import { SeoSettings } from "app/modules/pages/information/shop-list/shop-list.type";

export interface asset {
    _ref?: string | null,
}

export interface image {
    asset?: asset | null,
    targetURL?: string | null,
    image?: image | null,
    handle?: string | null,
}

export interface content {
    buttonLink?: string | null,
    excerpt?: string | null,
    title?: string | null,
    url?: string | null,
    label?: string | null,
    hor_alignment?: string | null,
    ver_alignment?: string | null
}

export interface mainLogo {
    image?: image | null,
    imageMobile?: image | null,
    targetURL?: string | null,
}

export interface items {
    content?: content | null,
    imageDesktop?: image | null,
    imageMobile?: image | null,
    handle?: string | null,
    iconImage?: image | null,
    title?: string | null,
    targetURL?: string | null,
    categoryOverview?: string | null,
    butlink1: string | null
}

export interface lowerBannerInformation {
    column1_buttoncta?: string,
    column1_buttonlink?: string,
    column1_label?: string,
    column1_icon?: image

}
export interface modules {
    items?: items[] | null,
    footerText?: string | null,
    title?: string | null,
    _type?: string | null,
    healthservices_tiles?: HealthservicesTiles[],
    column1: lowerBannerInformation,
    column2: lowerBannerInformation
    column3: lowerBannerInformation
}

export interface HealthservicesTiles {
    value: Value;
}

export interface HealthservicesTile {
    documentlistindex: number | null;
    healthservices_headertitle: string | null;
    healthservices_intro: string[] | null;
    newtab: boolean | false;
    imageMobile: string | null;
    imageDesktop: string | null;
    targetURL: string | null;
}

export interface HomePage {
    mainLogo?: mainLogo | null,
    megaMenu?: MegaMenu[] | null,
    modules?: modules[] | null
}

export interface MegaMenu {
    title?: string | null,
    subMenus?: MegaMenuChild[] | null
    targetURL?: string | null;
}

export interface SubMenu {
    menu?: Menu | null;
    categoryOverview?: string | null;
}

export interface Menu {
    menu?: Menu[] | null;
}

export interface Menu {
    title?: string | null,
    handle?: string | null,
    items?: items[] | null
    image?: image | null;
    categoryOverview?: string | null;
    _type?: string | null;
}


export interface MegaMenuChild {
    title?: string | null,
    children?: string[] | null,
    image?: any | null
    categoryOverview?: string | null,
    handle?:any | null,
    parent?:any | null,
}

export interface OpenMegaMenu {
    parentIndex?: number,
    subIndex?: number
}

export interface Blog {
    author: Author
    blogimage: Blogimage
    content: content[]
    metadesc: string
    isCatalogue: boolean
    slug: Slug
    title: string
    bodyHtml: string
    seo_settings: SeoSettings;
}

export interface Author {
    ref: any
    weak: any
    value: Value
    sanityType: string
    sanityKey: any
}

export interface Value {
    _createdAt: string,
    _updatedAt: string,
    image: any,
    title: any,
    documentlistindex?: number,
    healthservices_headertitle?: string,
    healthservices_image?: image,
    healthservices_intro?: string[],
    newtab?: boolean,
    targetURL?: string,

}

export interface Blogimage {
    asset: asset
    crop: any
    hotspot: any
    sanityType: string
    sanityKey: string
}

export interface Slug {
    current: string
    sanityType: string
    sanityKey: string
}

export interface AboutUs {
    image: aboutUsImage[];
    value1: Value1[];
    one_australia: OneAustralia;
    our_story: OurStory;
    customer_come: CustomerCome;
    seo_settings: SeoSettings;
    values: string;
    uplodstaff: string;
    customerTestimonials: string;
}

export interface aboutUsImage {
    _type: string;
    image: aboutUsImage;
    imageMobile?: any;
    asset?: any;
    content?: any;
    _key: string;
    staffName: string;
    company?: any;
    position?: any;
    count1: string;
    count2: string;
    social1: social1;
}

export interface social1 {
    _type: string;
    imageDesktop: imageDesktop[];
}

export interface imageDesktop {
    _key: string;
    _type: string;
    title: string;
    targetURL: string;
    image: image;
}

export interface child {
    _key: string;
    _type: string;
    marks: any[];
    text: string;
}

export interface aboutUsContent {
    _key: string;
    _type: string;
    children: child[];
    markDefs: any[];
    style: string;
}

export interface Value1 {
    _key: string;
    _type: string;
    image: aboutUsImage;
    value: string;
}

export interface OneAustralia {
    image1: aboutUsImage;
    oneAustralia: string;
}

export interface OurStory {
    image: aboutUsImage;
    ourStory: string;
}

export interface CustomerCome {
    image: aboutUsImage;
    customerCome: string;
}

export interface SupportEmail {
    mailto_link: string
    support_email_add: string
}
export interface DeliveryServiceLinks {
    delivery_service_name: string,
    delivery_service_image: image | null,
}

export interface SubmenuColumnItems {
    targetURL: string
    title: string
}

export interface SocmedLinks {
    socmed_name: string
    socmed_image: SocmedImage
}

export interface SocmedImage {
    image: image
    socmed_image: SocmedImage
}

export interface PaymentChannelLinks {
    payment_channel_name: string
    payment_channel_image: PaymentChannelImage
}

export interface PaymentChannelImage {
    image: image
    imageMobile: image
}


export interface Footer {
    copyright_text: string
    delivery_header: string
    hotline: string
    id: string
    payments_header: string
    schedule: string
    socmed_header: string
    submenu_header_column1: string
    submenu_header_column2: string
    support_email: SupportEmail
    delivery_service_links: DeliveryServiceLinks[]
    payment_channel_links: PaymentChannelLinks[]
    socmed_links: SocmedLinks[]
    submenu_column1_items: SubmenuColumnItems[]
    submenu_column2_items: SubmenuColumnItems[]
    privacypolicy_link: privacypolicy_links
    termsandconditions_link: termsandconditions_links
}
export interface privacypolicy_links {
    privacypolicy_url: string | null
}
export interface termsandconditions_links {
    termsandconditions_url: string | null
}
export interface HealthservicesImage {
    _type: string;
    image: image;
    targetURL: string;
    imageMobile?: any;
}

export interface HealthServices {
    healthservices_headertitle: string;
    healthservices_image: HealthservicesImage;
    healthservices_intro?: any;
    newtab: boolean;
    linkItem?: any;
    seo_settings?: SeoSettings;
}

export interface Terms {
    pageTitle: PageTitle[];
    pageHtml: string;
    seo_settings?: SeoSettings;
    id: string;
}

export interface PageTitle {
    _key: string;
    _type: string;
    children: child[];
    markDefs: any[];
    style: string;
}

export interface PrivacyPolicy {
    pri_pol_text: PriPolText;
    seo_settings?: SeoSettings;
}

export interface PriPolText {
    content: content[];
    pageHtml: string;
}
