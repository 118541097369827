<div mat-dialog-content>
    <div class="bg-gray4 px-5 py-4 flex gap-5 justify-between items-start">
        <div class="flex flex-col">
            <span class="font-medium text-2xl text-gray9">Reward history</span>
            <span>Only completed orders will be counted</span>
        </div>

        <button
            class="fuse-alert-dismiss-button mt-1"
            mat-icon-button
            (click)="closeDialog()"
        >
            <img
                class="w-6 h-6"
                src="../../../../../../assets/icons/exit.svg"
            />
        </button>
    </div>
    <div class="px-5 py-4 overflow-y-auto h-50">       
        <ng-container *ngFor="let item of data.rewardDetails.history_items.slice().reverse()">
            <div class="flex flex-wrap items-center border-b py-2 gap-x-3 gap-y-1">
                <img class="w-4 h-4" src="src=../../../../../assets/icons/dashboard/shop.svg" />
                <div class="w-full sm:w-auto">
                    {{item.points < 0 ? 'Redeemed' : 'Earned'}} <b>{{item.points}}</b> points from {{item.order_ids.length > 0 ? 'order #' : item.action}}                   
                        {{item.order_ids[0]}}                   
                </div>
                <div *ngIf="item.order_ids.length > 0" class="underline" (click)="viewPurchaseDetail(item.order_ids[0])">View</div>
                <div>{{item?.completed_at | date: 'dd/MM/yyyy'}}</div>
            </div>
        </ng-container>
    </div>
</div>
<div
    mat-dialog-actions
    class="flex justify-center bg-gray2 pt-5 w-full sm:w-120 px-5 py-4"
>
    <button
        mat-flat-button
        (click)="closeDialog()"
        class="bg-sdc-navy transparent rounded-md h-[40px] text-white w-40"
    >
        Close
    </button>
</div>
