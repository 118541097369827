import {
    Component,
    EventEmitter,
    Inject,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from '@angular/router';
import { filter, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { ElasticSearchProductRecordHit, ElasticSearchResult } from 'app/layout/common/ecomm-search/ecomm-search.type';
import { CartItemsService } from 'app/modules/components/cart-items/cart-items.service';
import { CMSService } from 'app/layout/common/services/cms.service';
import {
    Footer,
    HomePage,
    MegaMenu,
    MegaMenuChild,
    Menu,
    OpenMegaMenu,
    SubMenu,
} from 'app/layout/common/services/models/sanity/homepage';
import { MatDialog } from '@angular/material/dialog';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AzureADConfig, environment } from 'environments/environment';
import { DataService } from 'app/layout/common/services/dataservice.service';
import { StoreLocationResult } from 'app/layout/common/location-finder/location-finder.type';
import { LocationFinderService } from 'app/layout/common/location-finder/location-finder.service';
import { StoreCustomerDetailsResult } from 'app/layout/common/services/dataservice.type';
import { ShopifyCustomerAccessToken, ShopifyShippingAddress } from 'app/modules/pages/dashboard/account/shopify-account-type';
import { ShopifyAccountService } from 'app/modules/pages/dashboard/account/shopify-account.service';
import { BuyerIdentity, Cart, CartCreate, CartCreateResponse, CartInput, CartLine, Merchandise } from 'app/modules/components/cart-items/cart-create-type';
import { WishlistService } from 'app/modules/pages/dashboard/wishlist/wishlist.service';
import { CartItem } from 'app/modules/components/cart-items/cart-items-type';
import { UserInfoFormComponent } from 'app/modules/components/popup-modal/user-info-form/user-info-form.component';
import { InventorySourceService } from 'app/modules/components/inventory-source/inventory-source.service';
import { InventorySource } from 'app/modules/components/inventory-source/inventory-source.type';
import { CustomerYotpoRewards } from 'app/modules/pages/dashboard/account/models/customerYotpoRewards';
import * as _ from 'lodash'
import { EcommSearchService } from 'app/layout/common/ecomm-search/ecomm-search.service';
import { Title } from '@angular/platform-browser';
import { EncrDecrService } from 'app/shared/EncrDecrService';
@Component({
    selector: 'ecommerce-layout',
    templateUrl: './ecommerce.component.html',
    styleUrls: ['./ecommerce.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ECommerceLayoutComponent implements OnInit, OnDestroy {
    con = console;
    isScreenSmall: boolean;
    navigation: Navigation;
    navMenuIsOpen = false;
    userLocation = 'Shop 11, 14 Anthony Rd, WEST RYDE NSW 2114';
    cartItemCount = 0;
    currentPage: string;
    customerName: string;
    loginDisplay = false;
    homeDisplay = false;
    accountDisplay = false;
    shopDisplay = false;
    storeDisplay = false;
    selectedFirstLevelItem: Number;
    selectedMegaMenuItem = 999;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    elasticSearchProductRecord$: Observable<ElasticSearchProductRecordHit[]>;
    homePage_Menu: HomePage;
    homePage_Menu_v: HomePage = { mainLogo: null, megaMenu: [], modules: null };    
    footerPage$: Observable<Footer>;
    SubMenuPage$: Observable<Menu>;
    SubPage: Menu[];
    megaMenu: MegaMenu[];
    footer: Footer;
    rewardDetails: CustomerYotpoRewards;

    selectedStore$: Observable<StoreLocationResult>;
    currentShippingMethod$: Observable<string>;
    openMegaMenuIndex: OpenMegaMenu = { parentIndex: null, subIndex: 0 };
    @Output() querySuggestion = new EventEmitter<
        Observable<ElasticSearchProductRecordHit[]>
    >();
    @Output() isMobileMenuActive: boolean = false;
    Show: boolean = false;
    openTab = 0;
    customerDetail: StoreCustomerDetailsResult;
    customer$: Observable<StoreCustomerDetailsResult>;
    inventorySource$: Observable<InventorySource>;
    shopArry: any;

    constructor(
        private _eCommSearchService: EcommSearchService,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _cartItemService: CartItemsService,
        private _inventorySourceService: InventorySourceService,
        private _cmsService: CMSService,
        private route: ActivatedRoute,
        private _httpClient: HttpClient,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private dataService: DataService,
        private _locationFinderService: LocationFinderService,
        private _shopifyAccountService: ShopifyAccountService,
        @Inject(MSAL_GUARD_CONFIG)
        private msalGuardConfig: MsalGuardConfiguration,
        private _wishlistService: WishlistService, public dialog: MatDialog,
        private title: Title,
        private EncrDecr: EncrDecrService
    ) {
        this.selectedStore$ = _locationFinderService.selectedStore$;
        this.currentShippingMethod$ = this._locationFinderService.currentShippingMethod$
        this.customer$ = dataService.getCustomerDetails$;
        this.inventorySource$ = this._inventorySourceService.inventorySource$;

        this._router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                //console.log('Route change detected');

                this.title.setTitle(environment.masterPageTitle);
            }

            if (event instanceof NavigationEnd) {
                this.currentPage = event.url;
            }

            if (event instanceof NavigationError) {
                console.log(event.error);
            }
        });
    }

    public searchParameters = {
        query: '',
    };

    public setQuery({ query }: { query: string }) {
        this.searchParameters.query = query;
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        this.selectedMegaMenuItem = 999;
        //*************************************************************************** */
        //Inventory source call to get default store for online delivery
        if (this._inventorySourceService.currentInventorySourceValue == null) {
            this._inventorySourceService.getDeliveryStoreResult('').subscribe();
        }

        //*************************************************************************** */
        //Remove cart if user has already paid the order
        // var cartCreateResponse = this._cartItemService.currentCartCreateResponseValue;
        // if (cartCreateResponse != null) {
        //     var cartId = cartCreateResponse.cartCreate.cart.id;

        //     this._cartItemService.getCartItems(cartId)?.subscribe((res: any) => {
        //         if (res != null) {
        //             if (res?.data?.cart?.lines?.edges?.length == 0) {
        //                 this._cartItemService.clearCartLocalStorage();
        //             }
        //         }
        //     });
        // }
        //*************************************************************************** */
        //Executed when user logs in or after user has sign up
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS,
                ),
            )
            .subscribe((result: EventMessage) => {
                const payload = result.payload as AuthenticationResult;
                this.authService.instance.setActiveAccount(payload.account);
                if (payload.account.idTokenClaims.newUser) {
                    this.customerDetail = {
                        //azureID: payload.account.idTokenClaims.oid,
                        email: payload.account.idTokenClaims.emails[0],
                        firstName: "Super Star",
                        lastName: "",
                    };
                    //because user popup is now mandatory, so this call is not required
                    //this.dataService.storeCustomerDetails(this.customerDetail).subscribe();
                    var azid = this.EncrDecr.set(payload.account.idTokenClaims.oid);
                    var request = {
                        email: payload.account.idTokenClaims.emails[0],
                        firstName: payload.account.idTokenClaims.name,
                        lastName: payload.account.idTokenClaims.family_name,
                        azureID: azid.slice(0, 35),
                        newUser: true,
                    };
                    this.createCustomerByShopify(request).subscribe((response: ShopifyCustomerAccessToken) => {
                        this._shopifyAccountService.setCustomerAccessToken(response);
                        this.openDialogForCustomerCreation(response).subscribe(result => {
                            if (result.response) {
                                this.customerDetail = {
                                    //azureID: payload.account.idTokenClaims.oid,
                                    email: payload.account.idTokenClaims.emails[0],
                                    firstName: result.data.firstName,
                                    lastName: result.data.lastName,
                                };
                                this.dataService.storeCustomerDetails(this.customerDetail).subscribe();
                            }
                        });

                    });

                } else {
                    var azid = this.EncrDecr.set(payload.account.idTokenClaims.oid);
                    var request = {
                        email: payload.account.idTokenClaims.emails[0],
                        firstName: payload.account.idTokenClaims.name,
                        lastName: payload.account.idTokenClaims.family_name,
                        azureID: azid.slice(0, 35),
                        newUser: false,
                    };
                    this.createCustomerByShopify(request).subscribe((response: ShopifyCustomerAccessToken) => {
                        this._shopifyAccountService.setCustomerAccessToken(response);
                        this.customerDetail = {
                            //azureID: payload.account.idTokenClaims.oid,
                            email: payload.account.idTokenClaims.emails[0],
                            firstName: response.customerData.firstName == "" ? "Super Star" : response.customerData.firstName,
                            lastName: response.customerData.lastName
                        };
                        this.dataService.storeCustomerDetails(this.customerDetail);
                    }, error => {

                        var request = {
                            email: payload.account.idTokenClaims.emails[0],
                            firstName: payload.account.idTokenClaims.name,
                            lastName: payload.account.idTokenClaims.family_name,
                            azureID: payload.account.idTokenClaims.oid,
                            newUser: false,
                        };
                        this.createCustomerByShopify(request).subscribe((response: ShopifyCustomerAccessToken) => {
                            this._shopifyAccountService.setCustomerAccessToken(response);
                            this.customerDetail = {
                                //azureID: payload.account.idTokenClaims.oid,
                                email: payload.account.idTokenClaims.emails[0],
                                firstName: response.customerData.firstName == "" ? "Super Star" : response.customerData.firstName,
                                lastName: response.customerData.lastName
                            };
                            this.dataService.storeCustomerDetails(this.customerDetail);
                        });

                    }
                    );


                    this._shopifyAccountService.customerAccessToken$.subscribe(el => {
                        if (el != null) {

                            this.setCustomerDetails();
                            this.getWishlist();
                            this.getRewardsDetail();

                            this._cartItemService.getCustomerCart(el.data.customerAccessTokenCreate.customerAccessToken.accessToken)?.subscribe((res: any) => {
                                if (res != null) {
                                    if (res?.cart?.lines?.edges?.length > 0) {
                                        let cart = res?.cart as Cart;
                                        let cartLines: CartLine[] = [];
                                        let cartItems: CartItem[] = [];
                                        cart.lines.edges.forEach((el, index) => {
                                            let merchandise = el.node.merchandise;
                                            let cartLine: CartLine = { id: el.node.id, quantity: el.node.quantity, merchandiseId: merchandise.id, title: merchandise.title, image: merchandise.image, price: merchandise.price.amount } as CartLine;
                                            cartLines.push(cartLine);
                                            let productVariantId = parseInt(merchandise.id.substring(merchandise.id.lastIndexOf("/") + 1));
                                            let cartItem: CartItem = {
                                                objectID: index.toString(),
                                                variantAdminGraphId: merchandise.id,
                                                title: merchandise.title,
                                                parentTitle:"pt",
                                                image: merchandise.image,
                                                price: parseFloat(merchandise.price.amount.toString()),
                                                qty: el.node.quantity,
                                                isProductAvailable: true,
                                                isProductAddedToCart: true,
                                                productVariantId: productVariantId,
                                                inventoryItemId: 0,
                                                id: 1,
                                                bodyHtml: "",
                                                disclaimer: "",
                                                refundAndExchange: "",
                                                deliveryInfo: "",
                                                descriptionTag: "",
                                                productDisclaimer: "",
                                                isWishlist: true
                                            };
                                            cartItems.push(cartItem);
                                        });
                                        let accessToken = this._shopifyAccountService.currentCustomerAccessTokenValue;

                                        let buyerIdentity = { email: accessToken.customerData.email, countryCode: "AU" } as BuyerIdentity;

                                        this._cartItemService.updateCurrentCartInput(
                                            {
                                                cartId: cart.id,
                                                lines: cartLines,
                                                buyerIdentity: buyerIdentity
                                            } as CartInput);

                                        this._cartItemService.updateCurrentCartItems(cartItems);

                                        //this.getProductDetail();


                                        this._cartItemService.updateCurrentCartCreateResponse({
                                            cartCreate: { cart: res.cart } as CartCreate
                                        } as CartCreateResponse);

                                    } else {
                                        this._cartItemService.clearCartLocalStorage();
                                    }

                                }
                            });
                        }
                    });

                }

                this.setLoginDisplay();
                /*
                if (payload.account.name) {
                    this.loginDisplay = true;
                    this.customerName = payload.account.name;
                }
                */
            });
        //*************************************************************************** */
        //this.setCustomerDetails();
        //*************************************************************************** */

        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None,
                ),
            )
            .subscribe(() => {
                this.setLoginDisplay();
            });

        if (this._router.url.endsWith('home')) {
            this.currentPage = '/';
        } else {
            this.currentPage = this._router.url;
        }

        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({ matchingAliases }) => {
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this._cartItemService.currentCartItems.subscribe((items) => {
            if (items != null) {
                let sum: number = 0;
                //siba items.forEach(a => sum += a.qty);

                this.cartItemCount = items.length;
            }
            else
            {
                this.cartItemCount = 0;
            }
        });

        // this.setCustomerDetails();

        this.getWishlist();
        this.getRewardsDetail();

        //menu
        var newArray, title, categoryOverview, handle = null, image;
        
        this._cmsService.getHomePage().subscribe((m: HomePage) => {
            this.homePage_Menu_v = m;
        });
       
        this._cmsService.getSubMenu().subscribe((item) => {
            let chl: MegaMenuChild[] = [];
            item?.menu?.forEach((y) => {                
                image = y.image;
                title = y.title;
                categoryOverview = y.categoryOverview;
                newArray = y?.items?.map(function (singleElement) {
                    return singleElement;
                });
                handle = y?.handle;

                chl.push({
                    title: title,
                    children: newArray,
                    categoryOverview: categoryOverview,
                    handle: handle,
                    image: image,
                    parent: y._type
                });

            });
            //console.log('m-', this.homePage_Menu_v);
            this.homePage_Menu_v.megaMenu.forEach(m => {                             
                m.subMenus = chl.filter(l => l.parent == m.title.toLowerCase());
            })
            this.homePage_Menu = this.homePage_Menu_v;            
        });

        this._cmsService.getFooterSection().subscribe((item => {
            this.footer = item;
            //console.log(item);
        }))
        //console.log(this.footer);

        this.setLoginDisplay();
        this.onSDCLoginnSignup();
    }


    private setCustomerDetails() {
        if (this.customerDetail != null || this.customerDetail != undefined) {
            this.dataService.storeCustomerDetails(this.customerDetail).subscribe();
        }
    }
    //get product
    // private getProductDetail() {
    //     var cartItemList = this._cartItemService.currentCartItemsValue;
    //     if (cartItemList.length > 0) {


    //         cartItemList.forEach(e => {


    //             this._eCommSearchService.getElasticSearchResult(e.title, 0, '', '', '', '', '_alphabet_asc').subscribe((response: ElasticSearchResult) => {
    //                 if (response) {
    //                     e.maxOrderQuantity = response.hits[0].meta?.product?.max_order_quantity;
    //                     e.isOnSale = response.hits[0].isOnSale;
    //                     e.discountPercentage = response.hits[0].discountPercentage;
    //                     e.isOnSale = response.hits[0].isOnSale;
    //                     e.discountPercentage = response.hits[0].discountPercentage;
    //                     e.discountPrice = response.hits[0].discountPrice;
    //                     e.price = parseFloat(response.hits[0].price);

    //                     if (response.hits[0].compare_At_Price != null && response.hits[0].compare_At_Price != undefined)
    //                         e.compare_At_Price = parseFloat(response.hits[0].compare_At_Price);
    //                     else
    //                         e.compare_At_Price = 0;

    //                 }
    //             },
    //                 (error) => {
    //                     if (error.ok === false) {
    //                         console.log('error:', error.message);
    //                     }
    //                 },);
    //         });
    //     }
    // }

    private openDialogForCustomerCreation(shopifyCustomerAccessToken: ShopifyCustomerAccessToken): Observable<any> {
        return this.dialog
            .open(UserInfoFormComponent, {
                disableClose: true,
                data: shopifyCustomerAccessToken,
                width: '200',
                panelClass: 'disclaimer-modalbox'
            })
            .afterClosed();
    }
    toggleTabs($tabNumber: number) {
        if (this.openTab == $tabNumber) this.openTab = 0;
        else this.openTab = $tabNumber;
    }

    openAccordion(index: number) {
        if (this.selectedFirstLevelItem == index) this.selectedFirstLevelItem = 999
        else this.selectedFirstLevelItem = index
    }

    openMobileMegaMenu(index: number, link: string) {
        //alert(link);
        if (link !== undefined && link !== null && link !== '') {
            this.clear()
            if (!link.includes('promotions')) {
                var r = new RegExp('^(?:[a-z+]+:)?//', 'i');
                if (r.test(link)) {
                    window.open(link, "_blank");
                }

                this._router.navigateByUrl(link);
            }
            else {
                //alert('')
                this._router.routeReuseStrategy.shouldReuseRoute = function () {
                    return false;
                };
                this._router.onSameUrlNavigation = 'reload';
                this.dataService.setOption('displayTitle', 'Promotions');
                this.dataService.setOption('category_clicked', 'true');
                this.dataService.setOption('searchKeywords', null);
                this.dataService.setOption('categoryOverview', '');
                this.dataService.setOption('category', '');
                this._router.navigate(['promotions/promo-product'], {
                    queryParams: { searchtext: 'promotions' },
                });
            }
        } else {
            // this.GetSubMenu();
            this.selectedMegaMenuItem = index
            this.clear()
            this.shopDisplay = true
        }
    }
    hoverMobileMegaMenu(index: number, link: string) {
        //console.log(index);       
        this.selectedMegaMenuItem = index
        this.clear()
        this.shopDisplay = true
       
    }

    bottomAction(tabName: string) {
        if (tabName == "home") {
            this.dataService.setOption('noDataFound', false);
            this._router.navigate(['/home']);
            this.clear()
            this.homeDisplay = true;
        } else if (tabName == "store") {
            this._router.navigate(['/locations']);
            this.clear()
            this.storeDisplay = true;
        } else if (tabName == "account") {
            this.clear()
            this.accountDisplay = true;
        } else {           
            this.clear()
            this.selectedMegaMenuItem = 0
            this.shopDisplay = true;
        }
    }

    clear() {
        this.homeDisplay = false;
        this.accountDisplay = false;
        this.shopDisplay = false;
        this.storeDisplay = false;
    }

    navigateToMenuLink(value: any) {
        if (value.title !== 'Shop') {
            if (value.targetURL) {
                window.location.href = value.targetURL;
            }
        }
    }

    navigatetoAdvSearch(keyword: string, category: string, categoryOverview: string, displayTitle: string) {
        //alert('ok');
        this._router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this._router.onSameUrlNavigation = 'reload';
        this.dataService.setOption('displayTitle', displayTitle);
        this.dataService.setOption('category_clicked', 'true');
        this.dataService.setOption('searchKeywords', null);
        this.dataService.setOption('categoryOverview', categoryOverview);
        this.dataService.setOption('category', keyword);
        this._router.navigate(['course/' + category.replace(/ /g, '-')]);//{ queryParams: { searchtext: keyword },}
    }

    logout() {
        localStorage.clear();
        this.authService.instance.logout();
    }

    get checkNoDataFound(): boolean {
        var result = this.dataService.getOption();
        var filterCriteria = result['noDataFound'];
        if (filterCriteria) { return true; }
        else
            return false;
    }

    searchQueryText(): string {
        var result = this.dataService.getOption();
        return result["searchKeywords"];
    }

    setLoginDisplay() {
        // this.loginDisplay = this.authService.instance.getAllAccounts().length > 0 ; 

        // if (this.loginDisplay) {
        this.dataService.getCustomerDetails$.subscribe(res => {
            if (res) {
                this.loginDisplay = true;
                this.customerName = res.firstName;
            }
        });
        //}
    }

    toggle() {
        this.Show = !this.Show;
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                name,
            );
        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    onQuerySuggestion(hits: Observable<ElasticSearchProductRecordHit[]>): void {
        this.elasticSearchProductRecord$ = hits;
        this.querySuggestion.emit(this.elasticSearchProductRecord$);
    }

    toggleMenuOpen(isOpen?: boolean): void {
        this.navMenuIsOpen = isOpen ?? !this.navMenuIsOpen;
    }

    toggleSearchOpen(): void {
        this.isMobileMenuActive = !this.isMobileMenuActive;
    }

    toggleMegaMenuItem(parent: number, index: number): void {
        this.openMegaMenuIndex = { parentIndex: parent, subIndex: index };
    }

    showHeaderContent(): boolean {
        //console.log('sh-',this.currentPage?.indexOf('signup'));
        return this.currentPage?.indexOf('signup') == -1;
    }

    openLogin(): void {
        // this.dialog.open(LoginConfirmationComponent, {
        //   width: '380px'
        // });
    }

    createCustomerByShopify(request: any): Observable<any> {
        let api = `Customer/CreateCustomer`;
        const headers = { 'content-type': 'application/json' };
        const body = JSON.stringify(request);
        //let url = 'https://localhost:7036/api/Customer/CreateCustomer';
        let url = `${environment.url.base}${environment.url.microservice}/${api}`;
        return this._httpClient.post(url, body, { headers: headers });
    }

    setDeliveryLocation() {
        let formattedAddress;
        this.selectedStore$.subscribe((response: StoreLocationResult) => {
            formattedAddress = response.formattedAddress;
        });
        return formattedAddress;
    }

    login() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.instance.loginRedirect({
                ...this.msalGuardConfig.authRequest,
            } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    signUp() {
        this.authService.instance.getConfiguration().auth.authority = `${AzureADConfig.authority}${AzureADConfig.signupKey}`;

        if (this.msalGuardConfig.authRequest) {
            this.authService.instance.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    isPathAbsolute(path) {
       // console.log(path);
        if (path) {
            var r = new RegExp('^(?:[a-z+]+:)?//', 'i');
            if (r.test(path)) {
                window.open(path, "_blank");
            }
            else {
                this.clear()
                if (path.indexOf('?') == -1) {
                    this._router.navigate([`/${path}`]);
                }
                else {
                    this._router.navigateByUrl(path);
                }
            }
        }
    }

    getWishlist() {
        if (this.authService.instance.getAllAccounts().length > 0) {
            this.customer$.subscribe(result => {
                if (result && result.email)
                    this._wishlistService.getWishlist(result.email)
                        .pipe(take(1)).subscribe();
            });
        }
    }

    routToHome(path: string) {
        var result = this.dataService.getOption();
        var filterCriteria = result['noDataFound'];
        if (filterCriteria) {
            this.dataService.setOption('noDataFound', false);
        }
        this.isPathAbsolute(path);
    }

    private getRewardsDetail() {
        let customerAccessToken = this._shopifyAccountService.currentCustomerAccessTokenValue;
        if (customerAccessToken != null) {
            if (customerAccessToken.isAuthorized) {
                this._shopifyAccountService
                    .getCustomerYotpoRewards(customerAccessToken.data.customerAccessTokenCreate.customerAccessToken.accessToken)
                    .pipe(take(1)).subscribe((result: CustomerYotpoRewards) => {
                        this.rewardDetails = result;
                    });
            }
        }
    }
    onRightMove() {

        document.getElementById('menudiv').scrollLeft += 20;
    }
    onLeftMove() {

        document.getElementById('menudiv').scrollLeft -= 20;
    }

    onSDCLoginnSignup() {
        if (this._router.url.endsWith('sdc-signup')) {
            this.signUp();
        } else if (this._router.url.endsWith('sdc-login')) {
            this.login();
        }
        this.currentPage = '/';
    }
}
