import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { FuseNavigationService } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { CMSService } from 'app/layout/common/services/cms.service';
import { DataService } from 'app/layout/common/services/dataservice.service';
import { Footer, HomePage, MegaMenu, MegaMenuChild, Menu, OpenMegaMenu } from 'app/layout/common/services/models/sanity/homepage';
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
    selector     : 'empty-layout',
    templateUrl  : './empty.component.html',
    styleUrls: ['./empty.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EmptyLayoutComponent implements OnDestroy
{
    isScreenSmall: boolean;
    currentPage: string;
    homeDisplay = false;
    accountDisplay = false;
    shopDisplay = false;
    storeDisplay = false;
    selectedFirstLevelItem: Number;
    selectedMegaMenuItem = 999;
    
    private _unsubscribeAll: Subject<any> = new Subject<any>();
  
    homePage_Menu: HomePage;
    homePage_Menu_v: HomePage = { mainLogo: null, megaMenu: [], modules: null };    
    footerPage$: Observable<Footer>;
    SubMenuPage$: Observable<Menu>;
    SubPage: Menu[];
    megaMenu: MegaMenu[];
    openMegaMenuIndex: OpenMegaMenu = { parentIndex: null, subIndex: 0 };
    footer: Footer;
    /**
     * Constructor
     */
    constructor(
        private _cmsService: CMSService,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
       
        private _httpClient: HttpClient,
      
        private dataService: DataService,
   
    ) {
    }

    ngOnInit(): void {

         // Subscribe to media changes
         this._fuseMediaWatcherService.onMediaChange$
         .pipe(takeUntil(this._unsubscribeAll))
         .subscribe(({ matchingAliases }) => {
             this.isScreenSmall = !matchingAliases.includes('md');
         });

        this.selectedMegaMenuItem = 999;
      

        //menu
        var newArray, title, categoryOverview, handle = null, image;
        
        this._cmsService.getHomePage().subscribe((m: HomePage) => {
            this.homePage_Menu_v = m;
        });
       
        this._cmsService.getSubMenu().subscribe((item) => {
            let chl: MegaMenuChild[] = [];
            item?.menu?.forEach((y) => {                
                image = y.image;
                title = y.title;
                categoryOverview = y.categoryOverview;
                newArray = y?.items?.map(function (singleElement) {
                    return singleElement;
                });
                handle = y?.handle;

                chl.push({
                    title: title,
                    children: newArray,
                    categoryOverview: categoryOverview,
                    handle: handle,
                    image: image,
                    parent: y._type
                });

            });
            //console.log('m-', this.homePage_Menu_v);
            this.homePage_Menu_v.megaMenu.forEach(m => {                             
                m.subMenus = chl.filter(l => l.parent == m.title.toLowerCase());
            })
            this.homePage_Menu = this.homePage_Menu_v;            
        });

        this._cmsService.getFooterSection().subscribe((item => {
            this.footer = item;
            //console.log(item);
        }))
    }

    hoverMobileMegaMenu(index: number, link: string) {
        //console.log(index);       
        this.selectedMegaMenuItem = index
        this.clear()
        this.shopDisplay = true
       
    }
    clear() {
        this.homeDisplay = false;
        this.accountDisplay = false;
        this.shopDisplay = false;
        this.storeDisplay = false;
    }

    openMobileMegaMenu(index: number, link: string) {
        //alert(link);
        if (link !== undefined && link !== null && link !== '') {
            this.clear()
            if (!link.includes('promotions')) {
                var r = new RegExp('^(?:[a-z+]+:)?//', 'i');
                if (r.test(link)) {
                    window.open(link, "_blank");
                }

                this._router.navigateByUrl(link);
            }
            else {
                //alert('')
                this._router.routeReuseStrategy.shouldReuseRoute = function () {
                    return false;
                };
                this._router.onSameUrlNavigation = 'reload';
                this.dataService.setOption('displayTitle', 'Promotions');
                this.dataService.setOption('category_clicked', 'true');
                this.dataService.setOption('searchKeywords', null);
                this.dataService.setOption('categoryOverview', '');
                this.dataService.setOption('category', '');
                this._router.navigate(['promotions/promo-product'], {
                    queryParams: { searchtext: 'promotions' },
                });
            }
        } else {
            // this.GetSubMenu();
            this.selectedMegaMenuItem = index
            this.clear()
            this.shopDisplay = true
        }
    }

    toggleMegaMenuItem(parent: number, index: number): void {
        this.openMegaMenuIndex = { parentIndex: parent, subIndex: index };
    }

    navigatetoAdvSearch(keyword: string, category: string, categoryOverview: string, displayTitle: string) {
        //alert('ok');
        this._router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this._router.onSameUrlNavigation = 'reload';
        this.dataService.setOption('displayTitle', displayTitle);
        this.dataService.setOption('category_clicked', 'true');
        this.dataService.setOption('searchKeywords', null);
        this.dataService.setOption('categoryOverview', categoryOverview);
        this.dataService.setOption('category', keyword);
        this._router.navigate(['course/' + category.replace(/ /g, '-')]);//{ queryParams: { searchtext: keyword },}
    }
    onRightMove() {

        document.getElementById('menudiv').scrollLeft += 20;
    }
    onLeftMove() {

        document.getElementById('menudiv').scrollLeft -= 20;
    }
    routToHome(path: string) {
        var result = this.dataService.getOption();
        var filterCriteria = result['noDataFound'];
        if (filterCriteria) {
            this.dataService.setOption('noDataFound', false);
        }
        this.isPathAbsolute(path);
    }
    isPathAbsolute(path) {
        // console.log(path);
         if (path) {
             var r = new RegExp('^(?:[a-z+]+:)?//', 'i');
             if (r.test(path)) {
                 window.open(path, "_blank");
             }
             else {
                 this.clear()
                 if (path.indexOf('?') == -1) {
                     this._router.navigate([`/${path}`]);
                 }
                 else {
                     this._router.navigateByUrl(path);
                 }
             }
         }
     }
     showHeaderContent(): boolean {
        return this.currentPage?.indexOf('signup') == -1;
    } 
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
