import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';
import { HealthcheckComponent } from './modules/pages/healthcheck/healthcheck.component';
import { AuthConfirmationRequiredComponent } from './modules/auth/confirmation-required/confirmation-required.component';


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'home'},
    
  

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [           
            {
                path: 'about-us',
                loadChildren: () =>
                    import(
                        'app/modules/pages/information/about-us/about-us.module'
                    ).then((m) => m.AboutUsModule),
            }
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule,
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            }
        ],
    },

    // Landing routes
  
    {
        path: 'page-not-found-404',
        component: AuthConfirmationRequiredComponent,       
    },
    {
        path: 'Healthcheck',
        component: HealthcheckComponent,       
    },
    {
        path: 'healthcheck',
        component: HealthcheckComponent,       
    },
    // not required authentication urls
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'ecommerce',
        },
        children: [
            { 
                path: 'home', 
                loadChildren: () =>
                    import('app/modules/pages/home/home.module').then(
                        (m) => m.HomeModule,
                    ) 
            },
            {
                path: 'search/:productname',
                data: {page: 'advance-search'},
                loadChildren: () =>
                    import('app/modules/pages/products/products.module').then(
                        (m) => m.ProductsModule,
                    ),
            },
            {
                path: 'course/:productname',
                data: {page: 'advance-search'},
                loadChildren: () =>
                    import('app/modules/pages/products/products.module').then(
                        (m) => m.ProductsModule,
                    ),
            },
            {
                path: 'search',
                data: {page: 'advance-search'},
                loadChildren: () =>
                    import('app/modules/pages/products/products.module').then(
                        (m) => m.ProductsModule,
                    ),
            },
            {
                path: 'promotions/:productname',
                data: {page: 'advance-search'},
                loadChildren: () =>
                    import('app/modules/pages/products/products.module').then(
                        (m) => m.ProductsModule,
                    ),
            },
            {
                path: 'cart',
                data: {page: 'cart'},
                loadChildren: () =>
                    import('app/modules/pages/cart/cart.module').then(
                        (m) => m.CartModule,
                    ),
            },
            {
                path: 'checkout',
                data: {page: 'checkout'},
                loadChildren: () =>
                    import('app/modules/pages/checkout/checkout.module').then(
                        (m) => m.CheckoutModule,
                    ),
            },            
            {
                path: 'article/:detail',
                data: {page: 'product-detail'},
                loadChildren: () =>
                    import(
                        'app/modules/components/product-detail/product-detail.module'
                    ).then((m) => m.ProductDetailModule),
            },
            {
                path: 'pages/:detail',
                data: { page: 'blog-detail' },
                loadChildren: () =>
                    import(
                        'app/modules/pages/information/blog/detail/blog-detail.module'
                    ).then((m) => m.BlogDetailModule)
            },
            {
                path: 'contact',
                loadChildren: () =>
                    import(
                        'app/modules/pages/information/contact/contact.module'
                    ).then((m) => m.ContactModule),
            },
            {
                path: 'profesional-trainers',
                loadChildren: () =>
                    import(
                        'app/modules/pages/information/shop-list/shop.module'
                    ).then((m) => m.ShopListModule),
            },
            // {
            //     path: 'about-us',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/pages/information/about-us/about-us.module'
            //         ).then((m) => m.AboutUsModule),
            // },
            // {
            //     path: 'terms',
            //     loadChildren: () =>
            //         import('app/modules/pages/information/terms/terms.module').then(
            //             (m) => m.TermsModule)
            // },
            // {
            //     path: 'privacy-policy',
            //     loadChildren: () =>
            //         import('app/modules/pages/information/privacy-policy/privacy-policy.module').then(
            //             (m) => m.PrivacyPolicyModule)
            // },
           
            
            {
                path: 'login',
                loadChildren: () =>
                    import('app/modules/pages/sdc-login/sdc-login.module').then(
                        (m) => m.SdcLoginModule
                    ),
            },
            {
                path: 'mi-signup',
                loadChildren: () =>
                    import('app/modules/pages/sdc-signup/sdc-signup.module').then(
                        (m) => m.SdcSignupModule
                    ),
            },
            {
                path: 'reviews-comments',
                loadChildren: () =>
                    import('app/modules/pages/information/blog/blog.module').then(
                        (m) => m.BlogModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'ecommerce',
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            // {
            //     path: 'home',
            //     canActivateChild: [NoAuthGuard],
            //     loadChildren: () =>
            //         import('app/modules/pages/home/home.module').then(
            //             (m) => m.HomeModule,
            //         ),
            // },
           
            {
                path: 'product-catalog',
                data: {page: 'product-catalog'},
                loadChildren: () =>
                    import(
                        'app/modules/admin/advance-search/advance-search.module'
                    ).then((m) => m.AdvanceSearchModule),
            },
            {
                path: 'checkout',
                data: {page: 'checkout'},
                loadChildren: () =>
                    import('app/modules/pages/checkout/checkout.module').then(
                        (m) => m.CheckoutModule,
                    ),
            },
            {
                path: 'signup',
                data: {page: 'signup'},
                loadChildren: () =>
                    import('app/modules/pages/signup/signup.module').then(
                        (m) => m.SignupModule,
                    ),
            },
            {
                path: 'signin',
                data: {page: 'signin'},
                loadChildren: () =>
                    import('app/modules/pages/signin/signin.module').then(
                        (m) => m.SigninModule,
                    ),
            },
           
            {
                path: 'account',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'profile',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'purchases',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'purchases/detail',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'notifications',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'notifications/settings',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'payment-method',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'address',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'my-store',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
            {
                path: 'wishlist',
                loadChildren: () =>
                    import('app/modules/pages/dashboard/dashboard.module').then(
                        (m) => m.DashboardModule,
                    ),
            },
           
            {
                path: 'reward',
                loadChildren: () =>
                    import(
                        'app/modules/pages/information/reward/reward.module'
                    ).then((m) => m.RewardModule),
            },
            {
                path: 'advice',
                loadChildren: () =>
                    import(
                        'app/modules/pages/information/cms-advice/advice.module'
                    ).then((m) => m.AdviceModule),
            },           
           
            {
                path: 'blog',
                loadChildren: () =>
                    import('app/modules/pages/information/blog/blog.module').then(
                        (m) => m.BlogModule
                    ),
            },
            
            
            {
                path: 'payment-processing',
                loadChildren: () =>
                    import('app/modules/pages/payment-processing/payment-processing.module').then(
                        (m) => m.PaymentProcessingModule
                    ),
            },
           
        ],
    },

    { path: '**',  redirectTo: 'page-not-found-404' },
];
