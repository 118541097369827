import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ElasticSearchResult, InventoryResult } from './ecomm-search.type';
//import { InventoryResult } from 'app/modules/admin/advance-search/advance-search.types';
import { DataService } from '../services/dataservice.service';
import { InventorySource } from 'app/modules/components/inventory-source/inventory-source.type';
import { InventorySourceService } from 'app/modules/components/inventory-source/inventory-source.service';
import { PromotionsResult } from 'app/modules/pages/promotions/promotions.type';

@Injectable({
  providedIn: 'root'
})
export class EcommSearchService {
  inventorySource$: Observable<InventorySource>;
  // Private
  private _elasticSearchResult: BehaviorSubject<ElasticSearchResult | null> = new BehaviorSubject(null);
  private _inventorySearchResult: BehaviorSubject<InventoryResult | null> = new BehaviorSubject(null);
  constructor(private _httpClient: HttpClient, private dataService: DataService,
    private _inventorySourceService: InventorySourceService) {
    this.inventorySource$ = _inventorySourceService.inventorySource$;
  }

  get elasticSearchResult$(): Observable<ElasticSearchResult> {
    return this._elasticSearchResult.asObservable();
  }

  //product 
  getElasticSearchResult(keywords: string, page?: number, brand?: string, category?: string, price?: string, rating?: string, sortBy?: string, ProductIds?: string, IsOnSale: boolean = false, isVarient: boolean = true): Observable<ElasticSearchResult> {
    //console.log('adv-search');
    if (!sortBy) {
      sortBy = this.dataService.getOption()["sortKeyword"];
    }
    if (!brand) {
      brand = this.dataService.getOption()["filterCriteria"]?.brands?.toString() ?? '';
    }
    if (!category) {
      category = this.dataService.getOption()["filterCriteria"]?.category?.toString() ?? '';
    }
    if (!price) {
      price = this.dataService.getOption()["filterCriteria"]?.price?.toString() ?? '';
    }
    let locationId = 0;
    this.inventorySource$.subscribe((response: InventorySource) => {
      if (response != null)
        locationId = response.InventoryStoreLocationId;
    });
    let isCatgory_Clicked = this.dataService.getOption()["category_clicked"];
    //console.log('isCatgory_Clicked',isCatgory_Clicked);
    if (isCatgory_Clicked == 'true') {
      if (category == '') {
        category = keywords;
        keywords = '';
      }
    }
    if (isCatgory_Clicked == 'false') {
      if (keywords == '') {
        keywords = category;
        category = '';
      }
    }
    if (rating == undefined) {
      rating = '';
    }
    if (category == 'promotions') {
      category = '';
      IsOnSale = true;
    }
    if (keywords == 'promotions') {
      keywords = '';
      IsOnSale = true;
    }
    const params = new HttpParams()
      .set('keywords', keywords)
      .set('page', page)
      .set('brand', brand)
      .set('category', category)
      .set('rating', rating)
      .set('price', price)
      .set('sortBy', sortBy)
      .set('locationId', locationId)
      .set('ProductIds', ProductIds ?? '')
      .set("IsOnSale", IsOnSale);

    //let api = `integration/Search/Products`;
    //let url = 'https://localhost:7155/api/Search/Products';
    //let url = `${environment.url.base}${environment.url.microservice}/${api}`;

    let filterKey = keywords != "" ? keywords : category;
    //console.log("jsonFK-", filterKey);
    if (filterKey == "promo-product")
      isVarient = false;

    if (isVarient) {
      return this._httpClient.get<ElasticSearchResult>(`./assets/jsons/products.json`).pipe(
        tap((elasticSearchResult) => {
          let varVrnt = elasticSearchResult.hits.find(m => m.handle == filterKey).productVariantId;
          elasticSearchResult.hits = elasticSearchResult.hits.filter(m => m.productVariantId == varVrnt);
          this._elasticSearchResult.next(elasticSearchResult);
        })
      );
    }
    else {
      return this._httpClient.get<ElasticSearchResult>(`./assets/jsons/products.json`).pipe(
        tap((elasticSearchResult) => {
          elasticSearchResult.hits = elasticSearchResult.hits.filter(m => m.collections.includes(filterKey));
          this._elasticSearchResult.next(elasticSearchResult);
        })
      );
    }

  }

  getElasticSearchResult_PD(keywords: string, page?: number, brand?: string, category?: string, price?: string, rating?: string, sortBy?: string, ProductIds?: string, IsOnSale: boolean = false, isVarient: boolean = true): Observable<ElasticSearchResult> {
    //console.log('adv-search');
    if (!sortBy) {
      sortBy = this.dataService.getOption()["sortKeyword"];
    }
    if (!brand) {
      brand = this.dataService.getOption()["filterCriteria"]?.brands?.toString() ?? '';
    }
    if (!category) {
      category = this.dataService.getOption()["filterCriteria"]?.category?.toString() ?? '';
    }
    if (!price) {
      price = this.dataService.getOption()["filterCriteria"]?.price?.toString() ?? '';
    }
    let locationId = 0;
    this.inventorySource$.subscribe((response: InventorySource) => {
      if (response != null)
        locationId = response.InventoryStoreLocationId;
    });
    let isCatgory_Clicked = this.dataService.getOption()["category_clicked"];
    //console.log('isCatgory_Clicked',isCatgory_Clicked);
    if (isCatgory_Clicked == 'true') {
      if (category == '') {
        category = keywords;
        keywords = '';
      }
    }
    if (isCatgory_Clicked == 'false') {
      if (keywords == '') {
        keywords = category;
        category = '';
      }
    }
    if (rating == undefined) {
      rating = '';
    }
    if (category == 'promotions') {
      category = '';
      IsOnSale = true;
    }
    if (keywords == 'promotions') {
      keywords = '';
      IsOnSale = true;
    }
    const params = new HttpParams()
      .set('keywords', keywords)
      .set('page', page)
      .set('brand', brand)
      .set('category', category)
      .set('rating', rating)
      .set('price', price)
      .set('sortBy', sortBy)
      .set('locationId', locationId)
      .set('ProductIds', ProductIds ?? '')
      .set("IsOnSale", IsOnSale);

    //let api = `integration/Search/Products`;
    //let url = 'https://localhost:7155/api/Search/Products';
    //let url = `${environment.url.base}${environment.url.microservice}/${api}`;

    let filterKey = keywords != "" ? keywords : category;
    //console.log("jsonFK-PD", filterKey);

    return this._httpClient.get<ElasticSearchResult>(`./assets/jsons/products.json`).pipe(
      tap((elasticSearchResult) => {
        elasticSearchResult.hits = elasticSearchResult.hits.filter(m => m.handle == filterKey);
      })
    );

  }

  getInventoryLevel(request: any): Observable<InventoryResult> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(request);
    let api = `inventory/InventoryLevel`;
    //let url = 'https://localhost:7070/api/inventory/InventoryLevel';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;

    return this._httpClient.post<InventoryResult>(url, body, { 'headers': headers }).pipe(
      tap((inventoryResult) => {
        this._inventorySearchResult.next(inventoryResult);
      })
    );
  }
  getGetProductDetailsAtInventory(request: any): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = request;
    let api = `inventory/GetProductDetails`;
    //let url = 'https://localhost:7070/api/inventory/GetProductDetails';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;

    //return this._httpClient.post<InventoryResult>(url, body, { 'headers': headers });
    return this._httpClient.get<any>(`./assets/jsons/articledetail.json`).pipe(
      tap((inventoryResult) => {
        inventoryResult.hits = inventoryResult.hits.filter(m => m.productId == request.productId);
        //console.log("at-servie-html", inventoryResult);
      })
    );
  }
  getGetProductDetailsHtml(request: any): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = request;
    let api = `inventory/GetProductDetails`;
    //let url = 'https://localhost:7070/api/inventory/GetProductDetails';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;

    //return this._httpClient.post<InventoryResult>(url, body, { 'headers': headers });
    return this._httpClient.get<any>(`./assets/htmlfile/1001.html`).pipe(
      tap((inventoryResult) => {
        //inventoryResult.hits = inventoryResult.hits.filter(m => m.productId == request.productId);
        //console.log("pd-html", inventoryResult);
      })
    );
  }
  getOnSaleProducts(): Observable<PromotionsResult[]> {
    const headers = { 'content-type': 'application/json' }
    //const body = JSON.stringify(request);
    let api = `inventory/GetOnSaleproducts`;
    //let url = 'https://localhost:7070/api/inventory/InventoryLevel';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;

    return this._httpClient.get<PromotionsResult[]>(url, { 'headers': headers }).pipe(
      tap((promotionsResult) => {
      })
    );
  }

}
