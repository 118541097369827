<div
    class="flex w-full max-h-[486px] border-2 border-sdc-navy rounded-lg bg-white"
    *ngIf="hideQuickSearch && hideSearchPopup"
>
    <div class="flex flex-col border-r p-6 w-42 ...">
        <div class="flex-none font-bold text-lg mb-2">Brand</div>
        <ng-container
            class="flex-1"
            *ngIf="hits$ | async as elasticSearchResult"
        >
            <div class="flex-col overflow-x-auto">
                <div
                    *ngFor="let option of elasticSearchResult.vendors"
                    class="py-1 cursor-pointer hover:text-sdc-red"
                >
                    {{ option.description }}
                </div>
            </div>
        </ng-container>
        <div class="flex-none font-bold text-lg mt-5 mb-2">Category</div>
        <ng-container
            class="flex-1"
            *ngIf="hits$ | async as elasticSearchResult"
        >
            <div class="flex-col overflow-x-auto">
                <div
                    *ngFor="let option of elasticSearchResult.collection"
                    class="py-1 cursor-pointer hover:text-sdc-red"
                >
                    {{ option.description }}
                </div>
            </div>
        </ng-container>
    </div>
    <div class="p-6 mx-1 w-full ...">
        <div class="font-medium text-lg mb-3">Matching Products</div>
        <div class="grid grid-cols-2 gap-2 flex-row" cdkScrollable>
            <ng-container *ngIf="hits$ | async as elasticSearchResult">
                <div
                    *ngFor="
                        let option of elasticSearchResult.hits | slice : 0 : 8
                    "
                >
                    <ng-container
                        *ngTemplateOutlet="hits; context: {$implicit: option}"
                    >
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="w-full text-center font-medium mt-4">
            <button class="text-sdc-navy" (click)="navigateToAdvanceSearch()">
                View all results ({{ getResultCount }})
            </button>
        </div>
    </div>
</div>

<!-- Contact result template -->
<ng-template #hits let-result>
    <div
        class="flex items-center p-2 h-full border border-1 border-gray6 gap-2 rounded-md cursor-pointer hover:border-sdc-navy"
        (click)="navigateToProductDetails(result.id, result.title)"
    >
        <div
            class="flex shrink-0 w-16 h-16 items-center justify-center overflow-hidden dark:bg-primary-800"
        >
            <img class="h-14 w-14" [src]="result.image" />
        </div>
        <div class="leading-[140%] text-gray10">
            <span class="line-clamp-2" [innerHTML]="result.title"></span>
            <div class="font-medium text-xl pt-1">${{ result.price | number : '1.2-2' }}</div>
        </div>
    </div>
</ng-template>
