import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InventorySourceService } from 'app/modules/components/inventory-source/inventory-source.service';
import { ShopifyShippingAddress } from 'app/modules/pages/dashboard/account/shopify-account-type';
import { ShoppingMethod } from 'app/shared/ecommerce-contants';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { AutocompleteResult, StoreLocationResult } from './location-finder.type';

@Injectable({
  providedIn: 'root'
})
export class LocationFinderService {
  // Private
  private _autocompleteResult: BehaviorSubject<AutocompleteResult[] | null> = new BehaviorSubject(null);
  private _storeLocationResult: BehaviorSubject<StoreLocationResult[] | null> = new BehaviorSubject(null);
  private selectedStoreSubject: BehaviorSubject<StoreLocationResult | null> = new BehaviorSubject(null);
  private selectedAddressSubject: BehaviorSubject<ShopifyShippingAddress | null> = new BehaviorSubject(null);
  private currentShippingMethodSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private currentLocationChangeSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);
  public currentShippingMethod: Observable<string>;
  public selectedStore: Observable<StoreLocationResult>;
  
  /**
     * Constructor
     */
  constructor(private _httpClient: HttpClient, private _inventorySourceService: InventorySourceService) {
    this.selectedStoreSubject = new BehaviorSubject<StoreLocationResult>(JSON.parse(localStorage.getItem("SelectedStore")));
    this.selectedAddressSubject = new BehaviorSubject<ShopifyShippingAddress>(JSON.parse(localStorage.getItem("SelectedAddress")));
    this.currentShippingMethodSubject = new BehaviorSubject<string>(localStorage.getItem("ShippingMethod"));
    this.selectedStore = this.selectedStoreSubject.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
     * Getter for autocompleteResult
     */
  get autocompleteResult$(): Observable<AutocompleteResult[]> {
    return this._autocompleteResult.asObservable();
  }
  
  get locationChange$(): Observable<string> {
    return this.currentLocationChangeSubject.asObservable();
  }

  /**
   * Getter for selectedStore
   */
  get selectedStore$(): Observable<StoreLocationResult> {
    return this.selectedStoreSubject.asObservable();
  }

  get selectedAddress$(): Observable<ShopifyShippingAddress> {
    return this.selectedAddressSubject.asObservable();
  }

  get currentShippingMethod$(): Observable<string> {
    return this.currentShippingMethodSubject.asObservable();
  }

  /**
     * Getter for storeLocationResult
     */
  get storeLocationResult$(): Observable<StoreLocationResult[]> {
    return this._storeLocationResult.asObservable();
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get autocompleteResult
   */
  getAutocompleteResult(keywords: string): Observable<AutocompleteResult[]> {
    let api = `integration/GeoLocation/AutoComplete?keywords=${keywords}`;
    //let url = `https://localhost:7155/api/GeoLocation/AutoComplete?keywords=${keywords}`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;

    return this._httpClient.get<AutocompleteResult[]>(url).pipe(
      tap((autocompleteResult) => {
        this._autocompleteResult.next(autocompleteResult);
      })
    );
  }

  /**
   * Get storeLocationResult
   */
  getStoreLocationResult(placeId: string): Observable<StoreLocationResult[]> {
    let api = `integration/GeoLocation/StoreLocations?placeId=${placeId}`;
    //let url = `https://localhost:7155/api/GeoLocation/StoreLocations?placeId=${placeId}`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    return this._httpClient.get<StoreLocationResult[]>(url).pipe(
      tap((storeLocationResult) => {
        this._storeLocationResult.next(storeLocationResult);
      })
    );
  }

  changeStore(selectedStore: StoreLocationResult): Observable<StoreLocationResult> {
    var inventorySource = this._inventorySourceService.generateInventorySource(true, selectedStore.locationId, selectedStore.name);
    
    this.updateSelectedStore(selectedStore);
    this.updateShippingMethod(ShoppingMethod.ClickAndCollect);

    this._inventorySourceService.updateInventorySource(inventorySource);
    return of(selectedStore);
  }

  changeAddress(selectedAddress: ShopifyShippingAddress): Observable<ShopifyShippingAddress> {
    this.updateSelectedAddress(selectedAddress);

    // this._inventorySourceService.getDeliveryStoreResult(selectedAddress.zip).subscribe(data=> {
    //   var locationId = environment.defaultStoreId;

    //   if(data != null){
    //     var inventorySource = this._inventorySourceService.generateInventorySource(false, data.locationId, data.name);
    //     this._inventorySourceService.updateInventorySource(inventorySource);
    //   }

    // });
    this.changeInventorySource(selectedAddress.zip, false);
    
    return of(selectedAddress);
  }

  changeInventorySource(postcode: string, isStoreSelected: boolean): Observable<any> {
    this._inventorySourceService.getDeliveryStoreResult(postcode).subscribe(data=> {
      var locationId = environment.defaultStoreId;

      if(data != null){
        this.updateSelectedStore(null);
        var inventorySource = this._inventorySourceService.generateInventorySource(isStoreSelected, data.locationId, data.name);
        this._inventorySourceService.updateInventorySource(inventorySource);
      }

    });

    return of('default store');
  }

  public get selectedStoreValue(): StoreLocationResult {
    return this.selectedStoreSubject.value;
  }

  public updateShippingMethod(shippingMethod: string): void {
    this.updateCurrentShippingMethod(shippingMethod);
  }

  public updateLocationChange(data: string): void {
    this.currentLocationChangeSubject.next(data);
  }
  private updateCurrentShippingMethod(shippingMethod: string): void {
    if (shippingMethod == null) {
      localStorage.removeItem("ShippingMethod");
      this.currentShippingMethodSubject.next(null);
    } else {
      localStorage.setItem("ShippingMethod", shippingMethod);
      this.currentShippingMethodSubject.next(shippingMethod);
    }
  }

  private updateSelectedStore(selectedStore: StoreLocationResult): void {
    if (selectedStore== null) {
      localStorage.removeItem("SelectedStore");
      this.selectedStoreSubject.next(null);
    } else {
      localStorage.setItem("SelectedStore", JSON.stringify(selectedStore));
      this.selectedStoreSubject.next(selectedStore);
    }
  }

  private updateSelectedAddress(selectedAddress: ShopifyShippingAddress): void {
    if (selectedAddress== null) {
      localStorage.removeItem("SelectedAddress");
      this.selectedAddressSubject.next(null);
    } else {
      localStorage.setItem("SelectedAddress", JSON.stringify(selectedAddress));
      this.selectedAddressSubject.next(selectedAddress);
    }
  }

}
