<div class="px-5 py-3">
    <div class="flex gap-2 items-center">
        <img src="assets/icons/user.svg" class="h-11 w-11" alt="User">
        <div class="flex-1">
            <div class="text-black font-medium">Hi Mate!</div>
            <div class="text-black font-light">Login in for proccess your selected courses</div>    
        </div>
    </div>
    <div class="flex gap-3 mt-4">
        <button mat-flat-button (click)="login()" class="bg-sdc-navy w-full transparent text-white">
            Login
        </button> 
        <button mat-flat-button (click)="signUp()" cdkFocusInitial class="bg-transparent rounded text-black w-full bordered-btn">Sign Up
        </button>
    </div>
</div>