<span class="cursor-pointer hidden md:inline-block" (click)="settingsDrawer.toggle()">
    Click here
</span>
<!-- <span class="" > -->
<img src="assets/icons/white/map-pin.svg" class="cursor-pointer block md:hidden h-7 w-7 p-1" alt="Location"
    (click)="settingsDrawer.toggle()" />
<!-- </span> -->

<fuse-drawer class="w-screen min-w-screen sm:w-120 sm:min-w-120 z-999 bg-[#F9FAFB]" fixed [mode]="'over'"
    [name]="'settingsDrawer'" [position]="'left'" #settingsDrawer>
    <div class="flex flex-col w-full overflow-auto">
        <div class="flex flex-row items-center justify-center px-6 h-20 min-h-20 text-black bg-white">
            <div class="text-xl font-semibold tracking-tight">
                Get more discount on online training.
            </div>
            <button class="ml-auto" mat-icon-button (click)="clearInputText(); settingsDrawer.close()">
                <mat-icon class="text-current" [svgIcon]="'heroicons_outline:x'"></mat-icon>
            </button>
        </div>

        <div class="flex flex-col w-full location-finder">
            <mat-tab-group class="flex items-center">
                <mat-tab label="Offline Training">
                    <div class="mt-3 text-gray9 w-4/5 text-[14px]">
                        Now bellow locations are open for Offline training.
                    </div>
                    <br/>
                    <div class="yotpo-reward-steps-list">
                        <div class="yotpo-reward-steps-item yotpo-reward-steps-item-1">
                            <div class="yotpo-step-icons yotpo-reward-steps-icon" style="color: rgb(183, 198, 248);">
                                <div role="heading" aria-level="2" aria-label="1" class="yotpo-step-icon"></div>
                            </div>
                            <div class="yotpo-customized-text yotpo-reward-steps-title" role="heading" aria-level="2">
                                <div class="yotpo-inner-text"
                                    style="color: rgb(23, 27, 96); font-size: 24px; font-family: Gotham; font-weight: bold; font-style: normal;">
                                    <div>
                                        <div class="inline-block"><img src="assets/icons/green/check.svg" alt="First Step Icon"
                                                style="width: 30px; height: 25px !important;" /></div>
                                        <div class="inline-block"> Mumbai And Nearby</div>
                                    </div>
                                </div>
                            </div>
                            <div class="yotpo-customized-text yotpo-reward-steps-description" role="heading"
                                aria-level="3">
                                <div class="yotpo-inner-text"
                                    style="color: rgb(23, 27, 96); font-size: 20px; font-family: Gotham; font-style: normal;">
                                     mumbai,
                                    Thane, Kalyan, Vasai-Virar, Palghar</div>
                            </div>
                        </div> <br/>
                        <div class="yotpo-reward-steps-item yotpo-reward-steps-item-2">
                            <div class="yotpo-step-icons yotpo-reward-steps-icon" style="color: rgb(183, 198, 248);">
                                <div role="heading" aria-level="2" aria-label="2" class="yotpo-step-icon"></div>
                            </div>
                            <div class="yotpo-customized-text yotpo-reward-steps-title" role="heading" aria-level="2">
                                <div class="yotpo-inner-text"
                                    style="color: rgb(23, 27, 96); font-size: 24px; font-family: Gotham; font-weight: bold; font-style: normal;">
                                    <div>
                                        <div class="inline-block"><img src="assets/icons/green/check.svg" alt="First Step Icon"
                                                style="width: 30px; height: 25px !important;" /></div>
                                        <div class="inline-block"> Pune And Nearby</div>
                                    </div>
                                </div>
                            </div>
                            <div class="yotpo-customized-text yotpo-reward-steps-description" role="heading"
                                aria-level="3">
                                <div class="yotpo-inner-text"
                                    style="color: rgb(23, 27, 96); font-size: 20px; font-family: Gotham; font-style: normal;">
                                    All area in pune city.</div>
                            </div>
                        </div>

                    </div>
                    
                </mat-tab>
                <mat-tab label="Online Training" class="flex-1">
                    <div class="mt-3 mb-3 text-gray9 w-4/5 text-[14px]">
                        Online training can be provided in all over india locations 
                        as well as out side other country locations.
                    </div>


                    <div *ngIf="isNoAddressAvailable" class="flex justify-center mt-4">
                        <!-- <button mat-flat-button (click)="continueShopping(); settingsDrawer.close()"
                            class="bg-transparent border rounded border-sdc-navy text-sdc-navy px-4 bordered-btn">
                            Continue shopping
                        </button> -->
                        <img src="assets/images/banners/training.png" alt="First Step Icon"
                                                style="width: 500px; height: 360px !important;" />
                    </div>



                    <ng-container *ngIf="addressList$ | async as addressList">
                        <div class="grid grid-cols-1 gap-2">
                            <div [ngClass]="
                                    item.default
                                        ? 'bg-primaryYellowBackground'
                                        : 'bg-gray3'
                                " *ngFor="
                                    let item of addressList?.data
                                        .customerAddresses;
                                    let currentElementIndex = index
                                " class="col-span-1 rounded-md p-2 mr-2 border border-gray6">
                                <div class="flex flex-wrap gap-3">
                                    <label *ngIf="item.default" class="flex-1">
                                        <span class="font-medium">Default shipping address</span>
                                    </label>
                                </div>
                                <div class="text-gray9 my-2 text-2xl font-medium">
                                    {{ item.address2 }}
                                </div>
                                <div class="text-gray9 mb-1">
                                    Name: {{ item.name }}
                                </div>
                                <div class="text-gray9 mb-1">
                                    Address: {{ getFormattedAddress(item) }}
                                </div>
                                <div class="text-gray9 mb-1">
                                    Phone: {{ item.phone }}
                                </div>
                                <div class="flex justify-end p-2 m-2 w-full">
                                    <button mat-flat-button
                                        class="bg-transparent border rounded border-sdc-navy text-sdc-navy small-btn italic"
                                        (click)="
                                            selectShippingAddress(item);
                                            settingsDrawer.close()
                                        ">
                                        Select this address
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</fuse-drawer>

<!-- Contact result template -->
<ng-template #placeResult let-result>
    <div class="flex items-center">
        <div class="ml-3 truncate" [matTooltip]="result.description">
            <span [innerHTML]="result.description" class="text-sm"></span>
        </div>
    </div>
</ng-template>