import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { ElasticSearchProductRecordHit, ElasticSearchResult } from '../ecomm-search/ecomm-search.type';
import { Router } from '@angular/router';
import { DataService } from '../services/dataservice.service';
@Component({
  selector: 'search-advance',
  templateUrl: './search-advance.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchAdvanceComponent implements OnInit {

  @Input() hits$: Observable<ElasticSearchResult>;
  @Input() productSearchQuery: Observable<string>;
  hideQuickSearch: boolean;
  constructor(private _router: Router, private dataService: DataService) {
  }

  get getResultCount(): string {
    var result = this.dataService.getOption();
    var viewResultCount = result['totalProducts'];
    if (viewResultCount) {
      return viewResultCount;
    }
  }

  get hideSearchPopup(): boolean {
    var result = this.dataService.getOption();
    var viewResultCount = result['searchKeywords'];
    if (viewResultCount.length <= 3) {
      return false;
    }
    else {
      return true;
    }
  }

  ngOnInit(): void {
    this.hideQuickSearch = true;
  }

  navigateToAdvanceSearch(): void {
    this.hideQuickSearch = false;
    var result = this.dataService.getOption();
    this.dataService.setOption('callInventoryServices', true);
    this._router.navigate(['search'], { queryParams: { searchtext: result['searchKeywords'] } });
  }

  navigateToProductDetails(id: number, title: string) {
    this.hideQuickSearch = false;
   // this._router.navigate(['product/' + title.replace(/ /g, '-')], { queryParams: { productId: id } });
  }
}


