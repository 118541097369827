import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Blog, HomePage, Menu, MegaMenu, MegaMenuChild, SubMenu, AboutUs, Footer, HealthServices, Terms, PrivacyPolicy } from './models/sanity/homepage';
import { join } from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class CMSService {

  private _homePageResult: BehaviorSubject<HomePage | null> = new BehaviorSubject(null);
  private _contactUsPageResult: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _subMenuResult: BehaviorSubject<Menu | null> = new BehaviorSubject(null);
  private _blogResult: BehaviorSubject<Blog[] | null> = new BehaviorSubject(null);
  private _aboutUsResult: BehaviorSubject<AboutUs | null> = new BehaviorSubject(null);
  private _healthServicesResult: BehaviorSubject<HealthServices[] | null> = new BehaviorSubject(null);
  private _footerResult: BehaviorSubject<Footer | null> = new BehaviorSubject(null);
  private _termsResult: BehaviorSubject<Terms | null> = new BehaviorSubject(null);
  private _privacyPolicyResult: BehaviorSubject<PrivacyPolicy | null> = new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {
  }

  get homePageResult$(): Observable<HomePage> {
    return this._homePageResult.asObservable();
  }

  public get currentHomePageValue(): HomePage {
    return this._homePageResult.value;
  }

  get contactUsPageResult$(): Observable<any> {
    return this._contactUsPageResult.asObservable();
  }

  get subMenuResult$(): Observable<Menu> {
    return this._subMenuResult.asObservable();
  }

  get blogPageResult$(): Observable<Blog[]> {
    return this._blogResult.asObservable();
  }
  get footerPageResult$(): Observable<Footer> {
    return this._footerResult.asObservable();
  }

  get aboutUsPageResult$(): Observable<AboutUs> {
    return this._aboutUsResult.asObservable();
  }

  get healthServicesPageResult$(): Observable<HealthServices[]> {
    return this._healthServicesResult.asObservable();
  }

  get termsPageResult$(): Observable<Terms> {
    return this._termsResult.asObservable();
  }

  get privacyPolicyPageResult$(): Observable<PrivacyPolicy> {
    return this._privacyPolicyResult.asObservable();
  }

  getHomePage(): Observable<HomePage> {
    const params = new HttpParams().set('keywords', 'Home');
    let api = `CMS/GetPageContent`;

    return this._httpClient.get<HomePage>(`./assets/jsons/homemenu.json`).pipe(
      tap((result) => {
        //console.log(result.megaMenu);
        const megaMenu = result.megaMenu
        let convertedMenu: MegaMenu[] = []
        megaMenu.forEach((value) => {
          convertedMenu.push({
            title: value?.title?.toString(),
            subMenus: [],
            targetURL: value?.targetURL?.toString()
          })
        })
        result.megaMenu = convertedMenu;
        this._homePageResult.next(result);
      })
    );

  }

  getContactUsPage(): Observable<any> {
    const params = new HttpParams()
      .set('keywords', 'contact');

    let api = `CMS/GetPageContent`;
//`${environment.url.base}${environment.url.microservice}/${api}`, { params }
    return this._httpClient.get<any>(`./assets/jsons/contactus.json`).pipe(
      tap((result) => {
        this._contactUsPageResult.next(result);
      })
    );
  }

  getSubMenu(): Observable<Menu> {
    const params = new HttpParams().set('keywords', 'Megamenus');
    let api = `CMS/GetPageContent`;

    return this._httpClient.get<Menu>(`./assets/jsons/megamenus.json`).pipe(
      tap((result) => {
        this._subMenuResult.next(result);
      }));

  }

  getBlogPage(filterText?: string): Observable<Blog[]> {
    const params = new HttpParams()
      .set('keywords', 'Blog')
      .set('filterText', filterText == undefined ? '' : filterText);

    let api = `CMS/GetPageContent`;
    return this._httpClient.get<Blog[]>(`./assets/jsons/reviews.json`).pipe(
      tap((result) => {
        this._blogResult.next(result);
      }));
  }

  getAboutUsPage(): Observable<AboutUs> {

    return this._httpClient.get<AboutUs>(`./assets/jsons/aboutus.json`).pipe(
      tap((result) => {
        this._aboutUsResult.next(result);
      })
    );
    //const params = new HttpParams().set('keywords', 'aboutus')

    //let api = `CMS/GetPageContent`;
    // return this._httpClient.get<AboutUs>(`${environment.url.base}${environment.url.microservice}/${api}`, { params }).pipe(
    //   tap((result) => {
    //     this._aboutUsResult.next(result);
    //   })
    // );
  }


  getFooterSection(): Observable<Footer> {
    const params = new HttpParams()
      .set('keywords', 'Footer')

    let api = `CMS/GetPageContent`;
    return this._httpClient.get<Footer>(`./assets/jsons/getpagecontentfooter.json`).pipe(
      tap((result) => {
        this._footerResult.next(result);
      }));
  }

  getHealthServicesPage(): Observable<HealthServices[]> {
    const params = new HttpParams().set('keywords', 'healthservices_section')

    let api = `CMS/GetPageContent`;
    return this._httpClient.get<HealthServices[]>(`${environment.url.base}${environment.url.microservice}/${api}`, { params }).pipe(
      tap((result) => {
        this._healthServicesResult.next(result);
      })
    );
  }

  getTermsPage(): Observable<Terms> {
    const params = new HttpParams().set('keywords', 'terms')
    let api = `CMS/GetPageContent`;
    return this._httpClient.get<Terms>(`${environment.url.base}${environment.url.microservice}/${api}`, { params }).pipe(
      tap((result) => {
        this._termsResult.next(result);
      })
    );
  }


  getPrivacyPolicyPage(): Observable<PrivacyPolicy> {
    const params = new HttpParams().set('keywords', 'privacypolicy')

    let api = `CMS/GetPageContent`;
    return this._httpClient.get<PrivacyPolicy>(`${environment.url.base}${environment.url.microservice}/${api}`, { params }).pipe(
      tap((result) => {
        this._privacyPolicyResult.next(result);
      })
    );
  }
}
