export interface ShopifyShippingAddress {
    id: number;
    customer_id: number;
    first_name: string | null;
    last_name: string | null;
    name: string | null;    
    company: string | null;    
    address1: string | null;
    address2: string | null;
    city: string | null;
    province: string | null;
    province_code: string | null;
    country: string | "Australia";
    country_name: string | "Australia";
    country_code: string | "AU";
    zip: string | null;
    phone: string | null;
    default: boolean | null;
}

export interface ShopifyCustomerAccessToken 
{
    admin_graphql_api_id: string | null;
    data: CustomerAccessTokenCreate | null;
    isAuthorized: boolean;
    customerId: string | null;
    email: string | null;
    customerData:any;
}

export interface CustomerAccessTokenCreate {
    customerAccessTokenCreate: CustomerAccessToken | null;
}

export interface CustomerAccessToken {
    customerAccessToken: AccessToken | null;
    customerUserErrors: any[];
}

export interface AccessToken {
    accessToken: string | null;
    expiresAt: string | null;   
}
