import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, map, Observable, Subject, takeUntil } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config';
import { AppConfig, Scheme, Theme, Themes } from 'app/core/config/app.config';
import { Layout } from 'app/layout/layout.types';
import { AutocompleteResult, StoreLocationResult } from './location-finder.type';
import { LocationFinderService } from './location-finder.service';
import { UntypedFormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { DataService } from '../services/dataservice.service';
import { ShopifyAccountService } from 'app/modules/pages/dashboard/account/shopify-account.service';
import { ShopifyShippingAddress } from 'app/modules/pages/dashboard/account/shopify-account-type';
import { StoreCustomerDetailsResult } from '../services/dataservice.type';
import { ShoppingMethod } from 'app/shared/ecommerce-contants';

@Component({
  selector: 'location-finder',
  templateUrl: './location-finder.component.html',
  styleUrls: ['./location-finder.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class LocationFinderComponent implements OnInit {

  @Input() debounce: number = 300;
  @Input() minLength: number = 2;

  autocompleteResult$: Observable<AutocompleteResult[]>;
  storeLocationResult$: Observable<StoreLocationResult[]>;
  selectedAddress: StoreLocationResult = null;
  customerDetail$: Observable<StoreCustomerDetailsResult>;

  addressList$: Observable<any>;
  email: string = "";
  addressList: ShopifyShippingAddress[] = [];

  placeSearchControl: UntypedFormControl = new UntypedFormControl();
  selectedStoreId: Array<string> = [];

  expandSelectedItem: number;

  config: AppConfig;
  layout: Layout;
  scheme: 'dark' | 'light';
  theme: string;
  themes: Themes;

  showStores: boolean = false;
  isNoAddressAvailable: boolean = true;
  placeId: string = "";

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @ViewChild('settingsDrawer') drawer;

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _fuseConfigService: FuseConfigService,
    private _locationFinderService: LocationFinderService,
    private dataService: DataService,
    private _shopifyAccountService: ShopifyAccountService,
    private _dataService: DataService
  ) {
    this.customerDetail$ = _dataService.getCustomerDetails$;
  }



  refreshList(): void {
    this._shopifyAccountService.customerAccessToken$.subscribe(el => {
      if (el != null) {
        if (el.isAuthorized) {
          this.customerDetail$.subscribe(result => {
            this.email = result?.email;
            this.addressList$ = this._shopifyAccountService.addressList$;
            this.addressList$.subscribe(res => {
              if (res != null && res.data.customerAddresses != null && res.data.customerAddresses.length > 0) {
                var addressList = res.data.customerAddresses as ShopifyShippingAddress[];
                if (addressList)
                  this.isNoAddressAvailable = false;
                addressList.forEach(address => {
                  //todo fix circular dependancy this will fix,
                  //When address is changed in the UI it will update the inventory source
                  //if (address.default)
                  //this.selectShippingAddress(address);
                });
              }
            });

            this._shopifyAccountService.getCustomerAddresses(el.data.customerAccessTokenCreate.customerAccessToken.accessToken).subscribe(res => {

            });
          });
        }
      }
    });
    //let customerAccessToken = this._shopifyAccountService.currentCustomerAccessTokenValue;
  }

  selectStore(placeId: string) {
    this.storeLocationResult$ = this._locationFinderService.getStoreLocationResult(placeId);
  }

  clearInputText() {
    this.storeLocationResult$ = null;
    this.placeSearchControl.reset();
  }

  expandStore(index: number) {
    if (this.expandSelectedItem != index)
      this.expandSelectedItem = index
    else this.expandSelectedItem = 999
  }

  openStore(placeId: string) {
    const index = this.selectedStoreId.indexOf(placeId, 0);
    if (index > -1) {
      this.selectedStoreId.splice(index, 1);
    } else {
      this.selectedStoreId.push(placeId)
    }
  }

  public toggleStores() {
    this.showStores = !this.showStores;
  }

  viewStoreDetail(selectedStore: StoreLocationResult) {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this._router.onSameUrlNavigation = 'reload';
    //this._router.navigate(['shopdetail/' + selectedStore.name.replace(/ /g, '-')], { queryParams: { store: selectedStore.name } });
    this._router.navigate(['locations/' + selectedStore.name.toLocaleLowerCase().replace(/ /g, '-')]);
  }

  chooseAddress(place: StoreLocationResult) {
    this.isNoAddressAvailable = true
    this.selectedAddress = place
  }

  cancelAddress() {
    this.isNoAddressAvailable = false
    this.selectedAddress = null
  }

  // public handleChange($event: KeyboardEvent) {
  //   var keywords = ($event.target as HTMLInputElement).value;
  //   this.autocompleteResult$ = this._locationFinderService.getAutocompleteResult(keywords);
  // }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.placeSearchControl.valueChanges
      .pipe(
        debounceTime(this.debounce),
        takeUntil(this._unsubscribeAll),
        map((value) => {

          // Set the resultSets to null if there is no value or
          // the length of the value is smaller than the minLength
          // so the autocomplete panel can be closed
          // if ( !value || value.length < this.minLength )
          // {
          //     this.resultSets = null;
          // }

          // Continue
          return value;
        }),
        // Filter out undefined/null/false statements and also
        // filter out the values that are smaller than minLength
        //filter(value => value && value.length >= this.minLength)
      )
      .subscribe((value) => {
        this.autocompleteResult$ = this._locationFinderService.getAutocompleteResult(value);
      });



    // Subscribe to config changes
    this._fuseConfigService.config$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config: AppConfig) => {

        // Store the config
        this.config = config;
      });

    this.refreshList();

    this._locationFinderService?.locationChange$?.subscribe(e => {
      this.drawer?.toggle();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Set the layout on the config
   *
   * @param layout
   */
  setLayout(layout: string): void {
    // Clear the 'layout' query param to allow layout changes
    this._router.navigate([], {
      queryParams: {
        layout: null
      },
      queryParamsHandling: 'merge'
    }).then(() => {

      // Set the config
      this._fuseConfigService.config = { layout };
    });
  }

  /**
   * Set the scheme on the config
   *
   * @param scheme
   */
  setScheme(scheme: Scheme): void {
    this._fuseConfigService.config = { scheme };
  }

  /**
   * Set the theme on the config
   *
   * @param theme
   */
  setTheme(theme: Theme): void {
    this._fuseConfigService.config = { theme };
  }

  setDeliveryLocation(selectedStore: StoreLocationResult) {
    this.dataService.setOption('locationChange', true);
    this._locationFinderService.changeStore(selectedStore);
  }

  selectShippingAddress(address: ShopifyShippingAddress) {
    this._locationFinderService.updateShippingMethod(ShoppingMethod.OnlineDelivery);
    this._locationFinderService.changeAddress(address).subscribe();
  }

  continueShopping() {
    this._locationFinderService.updateShippingMethod(ShoppingMethod.OnlineDelivery);
    this._locationFinderService.changeInventorySource('', false);
  }

  getFormattedAddress(address: ShopifyShippingAddress): string {
    return `${address.address1}, ${address.city}, ${address.province_code}, ${address.zip},  ${address.country}`;
  }
}
