import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BreadCrumbCollection, ElasticSearchResult } from 'app/layout/common/ecomm-search/ecomm-search.type';
import { CMSService } from 'app/layout/common/services/cms.service';
import { DataService } from 'app/layout/common/services/dataservice.service';
import { Menu, items } from 'app/layout/common/services/models/sanity/homepage';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit,OnChanges {
  //console = console;
  @Input() collectionData: ElasticSearchResult;
  breadCrumbCollection: BreadCrumbCollection[] = [];
  menu: Menu[];
  constructor(private _cmsService: CMSService,
    private dataService: DataService,
    private _router: Router) {

  }

  ngOnInit(): void {
   
  }


  navigatetoAdvSearch(keyword: string, categoryOverview: string, displayTitle: string) {

    this.dataService.setOption('displayTitle', displayTitle);
    this.dataService.setOption('searchKeywords', keyword);
    this.dataService.setOption('categoryOverview', categoryOverview ?? '');
    this.dataService.setOption('category', keyword);

    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['course/' + keyword], {
      queryParams: { searchtext: keyword },
    });
  }
  getBreadCrumb(data:ElasticSearchResult) {
    this._cmsService.subMenuResult$.subscribe((result: Menu) => {      
      this.menu = result?.menu;
    });
    data?.hits[0]?.collections.forEach((element) => {
      let finditem = data?.collection.find(m => m.key == element);

      let mmElement = this.menu?.find((item) => item.title == finditem?.description);
      //find in submenuitem
      let subitem: items;
      if (mmElement == undefined) {
        this.menu.forEach(el => {
          if (subitem == undefined)
            subitem = el.items.find(subelement => subelement.title == finditem?.description);
        });
      }
      //end
      let catOverview: any;
      if (mmElement != undefined) {
        catOverview = mmElement.categoryOverview;
      }
      if (subitem != undefined) {
        catOverview = subitem.categoryOverview;
      }
      this.breadCrumbCollection.push(
        {
          key: element,
          description: finditem?.description,
          categoryOverview: catOverview
        }
      );
    });    
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getBreadCrumb(this.collectionData);
  }
}
