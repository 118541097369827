import { Component } from '@angular/core';

@Component({
  selector: 'app-healthcheck',
  templateUrl: './healthcheck.component.html',
  styleUrls: ['./healthcheck.component.scss']
})
export class HealthcheckComponent {

}
