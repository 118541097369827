import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShoppingMethod } from 'app/shared/ecommerce-contants';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { InventorySource, IventorySourceResponse } from './inventory-source.type';

@Injectable({
  providedIn: 'root'
})
export class InventorySourceService {
  private currentInventorySourceSubject: BehaviorSubject<InventorySource | null> = new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {
    this.currentInventorySourceSubject = new BehaviorSubject<InventorySource>(JSON.parse(localStorage.getItem("InventorySource")));

  }

  get inventorySource$(): Observable<InventorySource> {
    return this.currentInventorySourceSubject.asObservable();
  }

  public get currentInventorySourceValue(): InventorySource {
    return this.currentInventorySourceSubject.value;
  }

  public updateInventorySource(inventorySource: InventorySource): void {
    if (inventorySource == null)
      localStorage.removeItem("InventorySource");
    else {
      localStorage.setItem("InventorySource", JSON.stringify(inventorySource));

    }

    this.currentInventorySourceSubject.next(inventorySource);
  }

  getDeliveryStoreResult(postcode: string): Observable<IventorySourceResponse> {
    const params = new HttpParams()
      .set('postcode', postcode)

    let api = `integration/GeoLocation/GetDeliveryStore`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    return this._httpClient.get<IventorySourceResponse>(url, { params }).pipe(
      tap((inventorySourceResponse) => {
        var inventorySource: InventorySource = this.generateEmptyInventorySource();

        //if (this.currentInventorySourceSubject.value == null){
          inventorySource.InventoryStoreLocationId = inventorySourceResponse.locationId;
          inventorySource.BackupStoreLocationId = inventorySourceResponse.backupStoreLocationId;
          inventorySource.InventoryStore = inventorySourceResponse.name;

        //}
        //else
         // inventorySource = this.currentInventorySourceSubject.value;

        this.updateInventorySource(inventorySource);
      })
    );
  }


  //************************************************************************ */
  //Private methods
  private generateEmptyInventorySource(): InventorySource {
    return {
      HasDefaultAddress: false,
      IsStoreSelected: false,
      IsDeliveryAddressSelected: false,
      ShoppingMethod: ShoppingMethod.OnlineDelivery,
      InventoryStoreLocationId: 0,
      BackupStoreLocationId: 0,
      InventoryStore: ''
    } as InventorySource;
  }

  //Helper methods
  public generateInventorySource(isStoreSelected: boolean, inventoryStoreLocationId: number, inventoryStore: string): InventorySource {
    var inventorySource = this.currentInventorySourceValue;

      inventorySource.IsStoreSelected = isStoreSelected;
      inventorySource.IsDeliveryAddressSelected = !isStoreSelected;
      inventorySource.ShoppingMethod = isStoreSelected ? ShoppingMethod.ClickAndCollect : ShoppingMethod.OnlineDelivery;
      inventorySource.InventoryStoreLocationId = inventoryStoreLocationId;
      inventorySource.InventoryStore = inventoryStore;

      return inventorySource;
  }

}
