import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { CodeChallengeMethodValues } from '@azure/msal-common';
import { AzureADConfig } from 'environments/environment';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'login-confirmation',
    templateUrl: './login-confirmation.component.html',
    styleUrls: ['./login-confirmation.component.css'],
})

export class LoginConfirmationComponent implements OnInit {
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        // public dialogRef: MatDialogRef<LoginConfirmationComponent>,
        // @Inject(MAT_DIALOG_DATA) public data: any,
        private authService: MsalService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalBroadcastService: MsalBroadcastService,
        private _router: Router,
    ) { }

    ngOnInit(): void {       
        // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window        
        this.authService.instance.enableAccountStorageEvents();
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
            )
            .subscribe((result: EventMessage) => {
                if (this.authService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = "/";
                } else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            })
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    login() {
        // if (this.msalGuardConfig.authRequest) {
        //     this.authService.instance.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        // } else {
        //     this.authService.loginRedirect();
        // }
        this._router.navigate(['login']);
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    signUp() {
        this.authService.instance.getConfiguration().auth.authority=`${AzureADConfig.authority}${AzureADConfig.signupKey}`;
    
        if (this.msalGuardConfig.authRequest) {
            this.authService.instance.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }
}
