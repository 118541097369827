<ng-container>
    <!-- (focus)="handleOnFocus($event)"
                (focusout)="handleFocusOut($event)" 
                (keyup)="handleChange($event)" -->
    <div class="relative w-full sm:min-w-72 lg:min-w-100 search-field hidden sm:block">
        <mat-form-field class="fuse-mat-no-subscript w-full">
            <mat-icon matSuffix [svgIcon]="'heroicons_outline:search'"></mat-icon>
            <input matInput [placeholder]="'Search for course or project...'" 
                
                [formControl]="searchInputControl" />
        </mat-form-field>
        <ng-container *ngIf="elasticSearchResult$ | async as elasticSearchResult">
            <div class="absolute z-99999 mt-2 w-[840px] h-[500px]" *ngIf="hideQuickSearch && hideSearchPopup">
                <div class="w-full h-full bg-white rounded-lg drop-shadow-md border-sdc-navy border-2 flex ...">
                    <div class="max-h-[500px] flex flex-col border-r p-6 w-42 ...">
                        <div class="flex-none font-bold text-lg mb-2">
                            Brand
                        </div>
                        <ng-container class="flex-1" *ngIf="
                                elasticSearchResult$
                                    | async as elasticSearchResult
                            ">
                            <div class="flex-col overflow-x-auto">
                                <div *ngFor="
                                        let option of elasticSearchResult.vendors
                                    " class="py-1 cursor-pointer hover:text-sdc-red"
                                    (click)="navigateToSearch(option.description)">
                                    {{ option.description }}
                                </div>
                            </div>
                        </ng-container>
                        <div class="flex-none font-bold text-lg mt-5 mb-2">
                            Category
                        </div>
                        <ng-container class="flex-1" *ngIf="
                                elasticSearchResult$
                                    | async as elasticSearchResult
                            ">
                            <div class="flex-col overflow-x-auto">
                                <div *ngFor="
                                        let option of elasticSearchResult.collection
                                    " class="py-1 cursor-pointer hover:text-sdc-red"
                                    (click)="navigateToSearch(option.description)">
                                    {{ option.description }}
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="flex-1 p-6 mx-1 w-full ...">
                        <div class="font-medium text-lg mb-3">
                            Matching Products
                        </div>
                        <div class="grid grid-cols-2 gap-2 flex-row" cdkScrollable>
                            <ng-container *ngIf="
                                    elasticSearchResult$
                                        | async as elasticSearchResult
                                ">
                                <div *ngFor="
                                        let option of elasticSearchResult.hits
                                            | slice : 0 : 8
                                    ">
                                    <ng-container *ngTemplateOutlet="
                                            hits;
                                            context: {$implicit: option}
                                        ">
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div class="w-full text-center font-medium mt-4">
                            <div class="text-sdc-navy cursor-pointer" (click)="navigateToAdvanceSearch()">
                                View all results ({{ getResultCount }})
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-template #hits let-result>
    <div class="flex items-center p-2 h-full border border-1 border-gray6 gap-2 rounded-md cursor-pointer hover:border-sdc-navy"
        (click)="navigateToProductDetails(result.id, result.title)">
        <div class="flex shrink-0 w-16 h-16 items-center justify-center overflow-hidden dark:bg-primary-800">
            <img class="h-14 w-14" [src]="result.image" />
        </div>
        <div class="leading-[140%] text-gray10">
            <span class="line-clamp-2" [innerHTML]="result.title"></span>
            <div class="font-medium text-xl pt-1">${{ result.price | number : '1.2-2' }}</div>
        </div>
    </div>
</ng-template>

