import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {BreadcrumbsModule} from '../breadcrumbs/breadcrumbs.module';
import { MatTabsModule } from '@angular/material/tabs';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ExploreCategoryComponent } from './explore-category.component';

@NgModule({
    declarations: [ExploreCategoryComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        BreadcrumbsModule,     
        CarouselModule,
        MatTabsModule,
    ],
    exports: [
      ExploreCategoryComponent
    ]
})
export class ExploreCategoryModule {}
