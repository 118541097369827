import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { NgxPaginationModule } from 'ngx-pagination';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AzureADConfig, environment, protectedResources } from 'environments/environment';
import { RewardHistoryComponent } from './modules/pages/dashboard/account/reward-history/reward-history.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HealthcheckComponent } from './modules/pages/healthcheck/healthcheck.component';
import { EncrDecrService } from './shared/EncrDecrService';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: AzureADConfig.clientId,
            redirectUri: `${environment.url.root_frontend}/home`,
            postLogoutRedirectUri: `${environment.url.store_url}/account/logout`,
            authority: `${AzureADConfig.authority}${AzureADConfig.signinKey}`,
            knownAuthorities: [AzureADConfig.knownAuthorities[0]]
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    })
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    // const protectedResourceMap = new Map<string, Array<string>>();
    // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
    // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
    let url = `${environment.url.base}${environment.url.microservice}/${protectedResources.apiTodoList.endpoint}`;
    //console.log('test',url);

    protectedResourceMap.set(url, [
        {
            httpMethod: 'GET',
            scopes: []
        },
        {
            httpMethod: 'POST',
            scopes: []
        },
        {
            httpMethod: 'PUT',
            scopes: []
        },
        {
            httpMethod: 'DELETE',
            scopes: []
        }
    ]);
    //console.log('--',protectedResourceMap);

    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['openId']
        },
        loginFailedRoute: '/login-failed'
    };
}

@NgModule({
    declarations: [
        AppComponent, RewardHistoryComponent, HealthcheckComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),

        NgxPaginationModule,
        MsalModule,
        ReactiveFormsModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        EncrDecrService
    ],
    exports: [
    ],
    bootstrap: [
        AppComponent, MsalRedirectComponent
    ]
})
export class AppModule {
}
