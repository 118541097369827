<form  [formGroup]="infoForm" class="max-h-[550px] overflow-y-scroll px-0 py-4 sm:px-4">
    <div class="flex flex-col sm:flex-row gap-x-3">
        <a href="/home" class="mr-2">
            <img alt="Brand logo" class="w-24 h-auto pb-6" src="assets/images/mluo05q2/production/c8976aadb88b512cf0bb4957948ff2055f849157-3416x2232.png" />
        </a>
        <div class="">
            <h5 class="font-semibold text-black">Welcome to Star Discount Chemist</h5>
            <p class="mt-1">Hi! Please confirm a few details for your account.</p>
        </div>
    </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-3 py-2 text-[14px] mt-2">
            <div class="col-span-1">
                <div class="mb-5">
                    <label for="first_name" class="font-medium mb-1"><span class="text-red-600">*</span> First name</label>
                    <input type="text" id="first_name" formControlName="first_name" class="w-full border border-gray6 p-3 rounded-md text-sm"
                        placeholder="Your first name" maxlength="32" />
                </div>
            </div>
            <div class="col-span-1">
                <div class="mb-5">
                    <label for="last_name" class="font-medium mb-1"><span class="text-red-600">*</span> Last name</label>
                    <input type="text" id="last_name" formControlName="last_name" class="w-full border border-gray6 p-3 rounded-md text-sm"
                        placeholder="Your last name" maxlength="32"/>
                </div>
            </div>
            <div class="col-span-1">
                <div class="mb-5">
                    <label for="email" class="font-medium mb-1"><span class="text-red-600">*</span>Email</label>
                    <input type="email" id="email" formControlName="email" pattern=".+@\.com" class="w-full border border-gray6 p-3 rounded-md text-sm"
                        placeholder="Enter your email " disabled="disabled"/>
                </div>
            </div>
            <div class="col-span-1">
                <div class="mb-5">
                    <label for="phone" class="font-medium mb-1"><span class="text-red-600">*</span> Phone number</label>
                    <input type="text" id="phone" formControlName="mobile" class="w-full border border-gray6 p-3 rounded-md text-sm"
                        placeholder="Enter your phone number" />
                </div>
            </div>
            <div class="col-span-1 sm:col-span-2">
                <div class="mb-2 flex items-center gap-x-2">
                    <input type="checkbox" id="chkMarketing" formControlName="checkbox1" class="h-[14px] w-[14px] appearance-none checked:bg-primaryNavyDark" />
                    <label for="chkMarketing" class="mt-[1px] text-xs font-medium text-sdc-navy">As a Super Star Rewards Member I would love to receive rewards, special offers and updates via email.</label>
                </div>
                <div class="mb-5 flex items-center gap-x-2">
                    <input type="checkbox" id="chkTerms" formControlName="checkbox2" class="h-[14px] w-[14px] appearance-none checked:bg-primaryNavyDark" />
                    <label for="chkTerms" class="mt-[1px] text-xs font-medium text-sdc-navy">I agree to the SDC <a routerLink="/terms" target="_blank">Terms and Use</a> and <a routerLink="/privacy-policy" target="_blank">Privacy Policy.</a></label>
                </div>
            </div>
        </div>

        <div mat-dialog-actions class="flex pt-5 border-t border-primaryNavyLighter justify-end space-x-3">
            <p class="font-medium mb-1 w-full lg:w-60 text-gray9">
                {{saveMessage}}
            </p>
            <!-- <button class="mat-flat-button small-btn bg-white py-2.5 rounded-md text-sdc-navy text-bodySmall" (click)="onClickCancel()">Cancel</button> -->
            <button class="mat-flat-button small-btn bg-primaryNavy py-2.5 rounded-md text-white text-bodySmall" (click)="onClickYes()">Submit</button>
        </div>
</form>