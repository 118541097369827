<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Wrapper -->
<div *ngIf="homePage_Menu" class="flex flex-col flex-auto items-center w-full bg-gray-200">
    <!-- Header -->
    <div class="nav-wrapper" [class.menu-open]="navMenuIsOpen" [class.search-open]="searchIsOpen">
        <div class="nav">
            <div class="container nav-container">


                <!-- Nav logo -->
                <a (click)="routToHome(homePage_Menu.mainLogo.image.targetURL)" class="nav-logo-wrapper cursor-pointer">
                    <img alt="Brand logo" src="assets/images/logo/newlogo.png" />
                    <b>Mate Infotech</b> </a>
                    <div><span class="blink_me text-sdc-red text-[18px] font-bold">Software Training & Service </span></div>
                    <div  *ngIf="!isScreenSmall"> <span class="nav-desktop-heading">Make a call/Email to know more : +91 7385733488</span></div>
            </div>
        </div>
        <div class="nav">
            <div class="container nav-container">
                <!-- Searchbox -->
              

               
            </div>
        </div>
        <!-- Tabs -->

        <div 
            class="relative flex justify-center bg-white h-16 leading-[140%] border-b-2 border-b-sdc-navy">
            <div *ngIf="isScreenSmall" class="flex items-center px-1" (click)="onLeftMove()"><img _ngcontent-min-c248=""
                    src="assets/icons/blue/left_arrow.svg" alt="" class="w-3 h-3"></div>
            <div id="menudiv" class="container flex items-center overflow-x-scroll bg-white my-3 mx-2 gap-7">

                <div *ngFor="
                        let link of homePage_Menu.megaMenu;
                        let parentIndex = index
                    "
                    class="group text-sdc-navy border-b-4 border-b-white hover:border-b-sdc-yellow hover:border-b-4 cursor-pointer">

                    <div class="w-max text-[16px] md:text-[18px] text-center font-medium"
                        (mouseover)="hoverMobileMegaMenu(parentIndex, link?.targetURL)"
                        (click)="openMobileMegaMenu(parentIndex, link?.targetURL)">

                        <span *ngIf="parentIndex == 0; else else_content" style="color: red;">{{ link.title }}</span>
                        <ng-template #else_content>{{ link.title }}</ng-template>
                    </div>
                    <!-- MEGA MENU Desktop version -->
                    <div *ngIf="link.subMenus.length > 0"
                        class="hidden sm:block header-sub-content absolute top-5 md:top-5 transition group-hover:translate-y-3 translate-y-0 opacity-0 invisible group-hover:opacity-100 group-hover:visible duration-500 ease-in-out group-hover:transform z-50  w-[740px] transform">
                        <div class="border-2 border-sdc-navy rounded-xl relative top-6 bg-white shadow-xl w-full">
                            <div class="relative z-10">
                                <div class="h-[460px] relative grid grid-cols-10 gap-2">
                                    <div class="h-full overflow-y-scroll col-span-4 border-r border-gray5">
                                        <ul class="text-[16px] px-4 py-2">
                                            <li class="" *ngFor="
                                                    let subMenu of link.subMenus;
                                                    let i = index
                                                " [attr.data-index]="i">
                                                <a (click)="
                                                        toggleMegaMenuItem(
                                                            parentIndex,
                                                            i
                                                        )
                                                    " [ngClass]="{
                                                        'bg-gray4':
                                                            openMegaMenuIndex.subIndex ==
                                                                i &&
                                                                openMegaMenuIndex.parentIndex ==
                                                                    parentIndex
                                                    }"
                                                    class="font-bold flex text-[18px] justify-between items-center px-4 py-2 -mx-2 rounded-lg hover:bg-gray5 transition ease-in-out duration-300 text-gray10">
                                                    <div>
                                                        {{ subMenu?.title }}
                                                    </div>
                                                    <img class="h-4 w-4" src="assets/icons/chevron-left.svg" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div *ngIf="openMegaMenuIndex.parentIndex != null"
                                        class="h-full overflow-y-scroll col-span-6">
                                        <ul class="text-[16px] px-4 flex flex-col gap-0 py-2">
                                            <li>
                                                <a (click)="navigatetoAdvSearch(
                                                    link.subMenus[
                                                    openMegaMenuIndex.parentIndex ==
                                                    parentIndex
                                                    ? openMegaMenuIndex.subIndex
                                                    : 0
                                                    ]?.handle,
                                                link.subMenus[
                                                        openMegaMenuIndex.parentIndex ==
                                                        parentIndex
                                                        ? openMegaMenuIndex.subIndex
                                                        : 0
                                                        ]?.handle,
                                                        link.subMenus[
                                                                    openMegaMenuIndex.parentIndex ==
                                                                    parentIndex
                                                                        ? openMegaMenuIndex.subIndex
                                                                        : 0
                                                                ]?.categoryOverview,
                                                                link.subMenus[
                                                    openMegaMenuIndex.parentIndex ==
                                                    parentIndex
                                                    ? openMegaMenuIndex.subIndex
                                                    : 0
                                                    ]?.title)"
                                                    class="block font-normal px-4 hover:text-sdc-red -mx-2 py-2 text-gray10">
                                                    <div>
                                                        &lt;&lt;
                                                        {{
                                                        link.subMenus[
                                                        openMegaMenuIndex.parentIndex ==
                                                        parentIndex
                                                        ? openMegaMenuIndex.subIndex
                                                        : 0
                                                        ]?.title
                                                        }}>>
                                                    </div>
                                                </a>
                                            </li>
                                            <li *ngFor="
                                                    let child of link.subMenus[
                                                        openMegaMenuIndex.parentIndex ==
                                                        parentIndex
                                                            ? openMegaMenuIndex.subIndex
                                                            : 0
                                                    ]?.children
                                                ">
                                                <div>
                                                    <div class="inline-block">
                                                        <img [src]="child?.image?.asset._ref"
                                                            style="height: 40px; width: 40px;" />
                                                    </div>
                                                    <div class="inline-block">
                                                        <a class="block font-normal px-4 hover:text-sdc-red -mx-2 py-2 text-gray10"
                                                            [routerLink]="" (click)="
                                                            navigatetoAdvSearch(
                                                                child.imageMobile.handle ,
                                                                child.imageMobile.handle,
                                                                child.categoryOverview,
                                                                child.title
                                                            )
                                                        ">
                                                            <div>
                                                                {{ child.title }}
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>

                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="isScreenSmall" class="flex items-center px-1" (click)="onRightMove()"><img
                    _ngcontent-min-c248="" src="assets/icons/blue/right_arrow.svg" alt="" class="w-3 h-3"></div>
        </div>
       

     
    </div>

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full">
        <div class="flex flex-col flex-auto w-full sm:max-w-360 sm:shadow-lg sm:overflow-hidden bg-default">

            <router-outlet></router-outlet>
           
           
            <div class="grid grid-cols-2 md:grid-cols-3 gap-[8px] md:gap-[20px]"></div>
        </div>
    </div>

    <!-- Footer -->
    <div class="pb-[80px] sm:pb-0 relative flex flex-0 justify-center w-full z-49  print:hidden"
        style="background-color: #FAE910">

        <div class="flex flex-col items-center w-full container">
            <div class="flex flex-wrap nav-desktop-heading w-full mx-2 border-y border-[#43467F] py-10">
                <div class="w-full sm:w-1/2 md:w-1/5 flex flex-col gap-1 p-2">
                    <div class="mb-2">

                    </div>
                    <div class="flex flex-wrap items-center gap-1">

                    </div>

                </div>
                <div class="w-1/2 md:w-1/5 flex flex-col gap-1 p-2">
                    <div class="mb-2">
                        <a class="text-xl sm:text-2xl font-medium">{{
                            footer?.submenu_header_column1
                            }}</a>
                    </div>
                    <div *ngFor="let link of footer?.submenu_column1_items">
                        <div (click)="isPathAbsolute(link?.targetURL)"
                            class="text-gray9 text-md sm:text-xl py-1 hover:cursor-pointer">
                            {{ link?.title }}
                        </div>
                        <!-- (click)="isPathAbsolute(link?.targetURL)" -->
                    </div>
                </div>
                <div class="w-1/2 md:w-1/5 flex flex-col gap-1 p-2">
                    <div class="mb-2">
                        <a class="text-xl sm:text-2xl font-medium">{{
                            footer?.submenu_header_column2
                            }}</a>
                    </div>

                    <div *ngFor="let link of footer?.submenu_column2_items">
                        <div class="text-gray9 text-md sm:text-xl py-1 hover:cursor-pointer">
                            {{ link?.title }}
                        </div>
                        <!-- (click)="isPathAbsolute(link?.targetURL)" -->
                    </div>
                </div>

                <div class="w-full sm:w-1/2 md:w-1/5 flex flex-col gap-1 p-2">
                    <div class="mb-2">
                        <a class="text-xl sm:text-2xl font-medium">{{
                            footer?.socmed_header
                            }}</a>
                    </div>
                    <div class="flex gap-4">
                        <img class="w-20 h-15" alt="Brand logo" src="assets/images/logo/newlogo.png" />

                        <div class="hover:hover:cursor-pointer" *ngFor="let link of footer?.socmed_links">
                            <img alt="Brand logo" class="w-8 h-8" src="{{
                                    link?.socmed_image?.image?.asset?._ref
                                }}" />
                        </div>


                    </div>
                </div>
            </div>
            <div class="w-full py-2 flex gap-2 flex-wrap items-center justify-center">
                <span class="text-black">
                    {{footer?.copyright_text.replace('C','&copy;')}}

                </span>
                <span class="rounded-full h-1 w-1 bg-primaryNavyLight hidden sm:block"></span>
                <a class="text-black w-full sm:w-auto">
                    Terms & Conditions
                </a>
                <!-- pathDirective="{{footer?.termsandconditions_link?.termsandconditions_url}}" -->
                <span class="rounded-full h-1 w-1 bg-primaryNavyLight hidden sm:block"></span>
                <a class="text-black w-full sm:w-auto">
                    Privacy Policy
                </a>
                <!-- pathDirective="{{footer?.privacypolicy_link?.privacypolicy_url}}" -->
            </div>
        </div>
    </div>
</div>