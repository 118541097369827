export interface ElasticSearchProductRecord {
    hits?: ElasticSearchProductRecordHit[],
    page: number,
    nbHits: number,
    nbPages: number,
    hitsPerPage: number
}

export interface ElasticSearchResult {
    hits: ElasticSearchProductRecordHit[];
    collection: ElasticSearchCollection[];
    vendors: ElasticSearchVendor[];
    priceRange: ElasticSearchPrice[];
    page: number,
    nbHits: number,
    nbPages: number,
    hitsPerPage: number
}

export interface ElasticSearchProductRecordHit {
    id: string | null;
    objectID: string | null;
    variantAdminGraphId: string | null;
    title: string | null;
    parentTitle: string | null;
    image: string | null;
    price: string | null;
    vendor: string | null;
    isProductAvailable: boolean;
    productVariantId: string | null;
    inventoryItemId: string | null;
    qty: number | 0;
    available?: number | 1;
    body_Html_Safe: string | null;
    disclaimer: string | null;
    refundAndExchange: string | null;
    deliveryInfo: string | null;
    descriptionTag: string | null;
    ProductDisclaimer: string | null;
    discountPercentage?: number | null;
    isOnSale?: boolean;
    discountPrice?: number | null;
    max_order_quantity?: number | -1;
    collections: string[];
    meta:any | null;
    variant_sale_price:number | null;
    compare_At_Price : string | null;
    handle : string | null;
}

export interface ElasticSearchCollection {
    key: string | null;
    description: string | null;
}

export interface ElasticSearchVendor {
    key: string | null;
    description: string | null;
}

export interface ElasticSearchPrice {
    key: string | null;
    description: string | null;
}

export interface InventoryResult {
    productId: number | null;
    locationId: number | null;
    title: string | null;
    sku: string | null;
    available: number | null;
}
export interface BreadCrumbCollection {
    key: string | null;
    description: string | null;
    categoryOverview : string | null;
}