export class ShoppingMethod
{
    static ClickAndCollect = 'ClickAndCollect';
    static OnlineDelivery = 'OnlineDelivery';
}

export class DefaultStore
{
    static Kilkenny = 'Kilkenny';
}

