import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[pathDirective]'
})

export class PathDirective {
    @Input('pathDirective') path: string;

    constructor(private router: Router,
                private renderer: Renderer2,
                private el: ElementRef) { }

    ngOnInit(): void {
        if (this.isPathAbsolute(this.path)) {
            this.renderer.setAttribute(this.el.nativeElement, 'href', this.path);
            this.renderer.listen(this.el.nativeElement, 'click', (event) => {
                event.preventDefault();
                window.open(this.path, '_blank');
            });

        } else {
            this.renderer.setAttribute(this.el.nativeElement, 'routerLink', this.path);
            this.renderer.listen(this.el.nativeElement, 'click', (event) => {
                event.preventDefault();
                //this.router.navigate([this.path]);
                if(this.path.indexOf('?') == -1)
                {                    
                    this.router.navigate([this.path]);
                }
                else
                {                    
                    this.router.navigateByUrl(this.path);
                }
            });
        }
    }

    private isPathAbsolute(path) {
        if (path) {
            var r = new RegExp('^(?:[a-z+]+:)?//', 'i');
            if (r.test(path))
                return true;

            return false;
        }
    }
}
