import {
    Component,
    Output,
    EventEmitter,
    OnInit,
    Optional,
    ChangeDetectorRef,
    Input,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AutocompleteWidgetDescription } from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';
import { Observable, Subject } from 'rxjs';
import { DataService } from '../services/dataservice.service';
import { HomePage } from '../services/models/sanity/homepage';
import { EcommSearchService } from './ecomm-search.service';
import { ElasticSearchResult } from './ecomm-search.type';

@Component({
    selector: 'ecomm-search',
    templateUrl: './ecomm-search.component.html',
    styleUrls: ['./ecomm-search.component.scss'],
})
export class EcommSearchComponent implements OnInit {
    state: AutocompleteWidgetDescription['renderState'] = {
        currentRefinement: '',
        refine: () => null,
        indices: [],
    };

    @Output() onQuerySuggestionClick = new EventEmitter<{ query: string }>();
    @Output() querySuggestion = new EventEmitter<
        Observable<ElasticSearchResult>
    >();

    elasticSearchResult$: Observable<ElasticSearchResult>;
    homePageResult$: Observable<HomePage>;
    searchInputControl: UntypedFormControl = new UntypedFormControl();
    private: Subject<any> = new Subject<any>();
    hideQuickSearch: boolean;
    @Input() isMobile: boolean = false;

    constructor(
        @Optional()
        private _router: Router,
        private _eCommSearchService: EcommSearchService,
        private dataService: DataService,
    ) { }

    public ngOnInit() {
        var result = this.dataService.getOption();
        var hidePopup = result['hidePopup'];
        if (hidePopup) {
            this.hideQuickSearch = false;
        }
        this.elasticSearchResult$ =
            this._eCommSearchService.elasticSearchResult$;
    }

    get getResultCount(): string {
        var result = this.dataService.getOption();
        var viewResultCount = result['totalProducts'];
        if (viewResultCount) {
            return viewResultCount;
        }
    }

    get hideSearchPopup(): boolean {
        var result = this.dataService.getOption();
        var viewResultCount = result['searchKeywords'];
        if (viewResultCount?.length < 3) {
            return false;
        } else {
            return true;
        }
    }

    navigateToSearch(keyword: string): void {
        this.hideQuickSearch = false;
        this.dataService.setOption('callInventoryServices', true);
        this.dataService.setOption('searchResult', keyword);
        this.dataService.setOption('hidePopup', true);
        this.dataService.setOption('searchKeywords', keyword);
        this._router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this._router.onSameUrlNavigation = 'reload';
        this.hideQuickSearch = false;
        this._router.navigate(['search'], { queryParams: { searchtext: keyword } });
    }

    public handleFocusOut($event: KeyboardEvent) {
        setTimeout(()=> {
            this.hideQuickSearch = false;
        }, 160)
    }   
    
    public handleOnFocus($event: KeyboardEvent) {
        var keywords = ($event.target as HTMLInputElement).value;
        if (keywords.length > 3) {
            this.hideQuickSearch = true;
        }
    }   

    public handleChange($event: KeyboardEvent) {
        var keywords = ($event.target as HTMLInputElement).value;
        if (keywords.length > 3) {
            this.dataService.setOption('category_clicked', 'false');
            this.dataService.setOption('category', null);
            this.dataService.setOption('filterCriteria', '');
            this.dataService.setOption('sortKeyword','');
            this.hideQuickSearch = true;            
            this.elasticSearchResult$ =
                this._eCommSearchService.getElasticSearchResult(keywords, 0);
            this.dataService.setOption('searchKeywords', keywords);
            this.elasticSearchResult$.subscribe(
                (value) => {
                    if(value)
                    {
                        this.dataService.setOption('searchResult', value);
                        this.dataService.setOption('noDataFound', false);
                        this.dataService.setOption('totalProducts', value.nbHits);
                    }
                    else
                    {
                        this.dataService.setOption('noDataFound', true);  
                    }
                    
                },
                (error) => {
                    if (error.ok === false) {
                        this.dataService.setOption('noDataFound', true);
                    }
                },
            );
            this.querySuggestion.emit(this.elasticSearchResult$);
        }
    }

    navigateToAdvanceSearch(): void {
        this.hideQuickSearch = false;
        this.isMobile = false;
        var result = this.dataService.getOption();
        this.dataService.setOption('callInventoryServices', true);
        this._router.navigate(['search'], {
            queryParams: { searchtext: result['searchKeywords'] },
        });
    }

    navigateToProductDetails(id: number, title: string) {
        this.hideQuickSearch = false;
        this.isMobile = false;
        // this._router.navigate(['product/' + title.replace(/ /g, '-')], {
        //     queryParams: { productId: id },
        // });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Escape
        if (event.code === 'Escape') {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
