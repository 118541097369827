<div class="w-full bg-primaryYellowBackground py-8">
    <div class="container">
        <h6 class="flex-1 font-bold text-gray10 text-3.5xl mb-8">

        </h6>
        <div class="border-none border sm:border-solid rounded-lg py-8 px-0 sm:px-6 border-gray6">
            <h6 class="flex-1 font-bold text-gray10 text-xl mb-4">
                Quick Links (Courses)
            </h6>
            <div class="columns-2 sm:columns-3 md:columns-4 lg:columns-5 gap-3">
                <div class="break-inside-avoid" *ngFor="let link of shopArry; let i = index">
                    <div class="font-medium text-gray10 mb-1">
                        {{ link.title }}
                    </div>
                    <div class="mb-3">
                        <span *ngFor="let child of link.children; let c = index">
                            <a class="text-sm leading-[16.8px] cursor-pointer text-gray9 hover:text-sdc-red"
                                (click)="navigatetoAdvSearch(child.handle, child.handle, child.categoryOverview, child.title)">
                                <!-- <a class="text-sm leading-[16.8px] cursor-pointer text-gray9 hover:text-sdc-red"
                                href="/course/{{link.title}}?searchtext={{child.handle}}&category_clicked=1&category_Desc={{child.categoryOverview}}&disTitle={{child.encTitle}}"> -->
                                {{ child.title }}
                                <span class="font-bold text-gray10" *ngIf="c < link.children.length - 1">|</span>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>