// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    yotpoInstanceId: 360097,
    defaultStoreId: 61741203585,
    production: false,
    masterPageTitle: 'Mate Infotech software training and service',
    url: {
        store_url: "https://starpharmacyau.myshopify.com",
        root_frontend: "http://localhost:4200",
         //base: "https://localhost:7180",
         //microservice: "",  
         //base: "https://sdc-ecom-aks-ingress-dns.australiacentral.cloudapp.azure.com",
         base: "https://sdc-frontend-fmd6ahcwgxhbcshd.z01.azurefd.net/",
         microservice: "/test",
         yotpo_url:"https://cdn-widgetsrepository.yotpo.com/v1/loader/gKFpkWMOFtliZOtmmsafqQ"
    }
};

export const AzureADConfig = {
    clientId: "79657d0d-bd98-4c15-8070-4e16b0eb378a_d",
    authority: "http://adspgecom.b2clogin.com/adspgecom.onmicrosoft.com/",
    signinKey:"B2C_1_spg-authflow_d",
    signupKey:"B2C_1_sdc_signup_d",
    resetPassword:"B2C_1_resetpwd_d",
    knownAuthorities:  ["adspgecom.b2clogin.com_d"]
};

export const protectedResources = {
    apiTodoList: {
        endpoint: "Customer/CreateCustomer"
    }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
