import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {StoreCustomerDetailsResult} from './dataservice.type';

@Injectable()
export class DataService {
    private customerDetailsSubject: BehaviorSubject<StoreCustomerDetailsResult | null> =
        new BehaviorSubject(null);
    public customerDetails: Observable<StoreCustomerDetailsResult>;
    private data = {};

    constructor() {
        this.customerDetailsSubject =
            new BehaviorSubject<StoreCustomerDetailsResult>(
                JSON.parse(localStorage.getItem('CustomerDetails')),
            );
        this.customerDetails = this.customerDetailsSubject.asObservable();
    }

    get getCustomerDetails$(): Observable<StoreCustomerDetailsResult> {
        return this.customerDetailsSubject.asObservable();
    }

    public get currentCustomerDetailsValue(): StoreCustomerDetailsResult {
        return this.customerDetailsSubject.value;
    }

    storeCustomerDetails(
        selectedStore: StoreCustomerDetailsResult,
    ): Observable<StoreCustomerDetailsResult> {
        this.insertCustomerDetails(selectedStore);
        return of(selectedStore);
    }

    private insertCustomerDetails(
        selectedStore: StoreCustomerDetailsResult,
    ): void {
        if (selectedStore == null) {
            localStorage.removeItem('CustomerDetails');
            this.customerDetailsSubject.next(null);
        } else {
            localStorage.setItem(
                'CustomerDetails',
                JSON.stringify(selectedStore),
            );
            this.customerDetailsSubject.next(selectedStore);
        }
    }

    setOption(option, value) {
        this.data[option] = value;
    }

    clearOption()
    {
        this.data = {};
    }

    getOption() {
        return this.data;
    }
}
