<div class="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-auto min-w-0">
    <div class="md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full  py-8 px-4 sm:p-12 md:p-16 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none sm:bg-card">
        <div class="w-full">
            <!-- Logo -->
            <div class="w-full">
                <img src="assets/images/information/page404.png">
            </div>           
        </div>
    </div>
   
</div>
