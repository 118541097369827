import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CMSService } from 'app/layout/common/services/cms.service';
import { DataService } from 'app/layout/common/services/dataservice.service';
import {
    Menu,
    MegaMenu,
    MegaMenuChild,
    SubMenu,
} from 'app/layout/common/services/models/sanity/homepage';
import { Observable } from 'rxjs';

@Component({
    selector: 'explore-category',
    templateUrl: './explore-category.component.html',
    styleUrls: ['./explore-category.component.scss'],
})
export class ExploreCategoryComponent implements OnInit {
    Arr = Array;
    math = Math;
    SubMenuPage$: Observable<Menu>;
    SubPage: Menu[];
    shopArry: MegaMenuChild[] = [];
    constructor(
        private _cmsService: CMSService,
        private _router: Router,
        private dataService: DataService,
    ) { }
    ngOnInit(): void {
        this.getExporeCategory();
    }

    getExporeCategory() {
        this.SubMenuPage$ = null;
        this.SubMenuPage$ = this._cmsService.subMenuResult$;
        this._cmsService.getSubMenu().subscribe();
        this.SubMenuPage$[0] = this.SubMenuPage$?.subscribe((item) => {
            return item?.menu;
        });

        var newArray, title;
        this.SubMenuPage$?.forEach((subMenuitems) => {
            this.shopArry = [];
            subMenuitems?.menu.forEach((i) => {
                title = i.title;               
                newArray = i?.items?.map(function (singleElement) {                    
                    return {
                        title:singleElement.title, 
                        handle:singleElement.imageMobile.handle, 
                        // categoryOverview :encodeURIComponent(singleElement.categoryOverview),
                        // encTitle : encodeURIComponent(singleElement.title)
                        categoryOverview :singleElement.categoryOverview,
                        encTitle : singleElement.title
                     };
                });                
                this.shopArry?.push({ title: title, children: newArray, categoryOverview: "" });
            });
        });
    }

    navigatetoAdvSearch(keyword: string, category: string, categoryOverview: string, displayTitle: string) {
          
        this._router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this._router.onSameUrlNavigation = 'reload';
        this.dataService.setOption('displayTitle', displayTitle);
        this.dataService.setOption('category_clicked', 'true');
        this.dataService.setOption('searchKeywords', null);
        this.dataService.setOption('categoryOverview', categoryOverview);
        this.dataService.setOption('category', keyword);
        this._router.navigate(['course/' + category.replace(/ /g, '-')], {
            queryParams: { searchtext: keyword },
        });
        // this.dataService.setOption('searchKeywords', keyword);
        // this.dataService.setOption('categoryOverview', categoryOverview);
        // this.dataService.setOption('category', keyword);

        // this._router.routeReuseStrategy.shouldReuseRoute = function () {
        //     return false;
        // };
        // this._router.onSameUrlNavigation = 'reload';
        // this._router.navigate(['course/' + category.replace(/ /g, '-')], {
        //     queryParams: { searchtext: keyword },
        // });
    }
}
