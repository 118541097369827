export interface CartInput 
{
    cartId: string | null;
    lines: CartLine[];
    buyerIdentity: BuyerIdentity;
}

export interface CartLine 
{
    id: string | null;
    quantity: number | null;
    merchandiseId: string | null;
    title: string | null;
    image: string | null;
    price: string | null;
}

export interface BuyerIdentity 
{
    email: string | null;
    phone: string | null;
    countryCode: string | null;
}

export interface CartCreateResponse
{
    cartCreate: CartCreate;
}

export interface CartCreate
{
    cart: Cart;
}

export interface GetCartResponse
{
    cart: Cart;
}

export interface Cart
{
    id: string | null;
    buyerIdentity: BuyerIdentity;
    createdAt: string | null;
    updatedAt: string | null;
    lines: Lines;
    attributes: any;
    cost: Cost;

}

export interface Lines
{
    edges: Edge[];
}

export interface Edge
{
    node: Node;
}

export interface Node
{
    id: string | null;
    quantity: number;
    merchandise: Merchandise;
}

export interface Merchandise
{
    id: string | null;
    image: string | null;
    price: Price | null;
    title: string | null;
}

export interface Price {
    amount: string | null;
}

export interface Cost
{
    totalAmount: Totalamount;
    subtotalAmount: Totalamount;
    totalTaxAmount: any;
    totalDutyAmount: any;
}

export interface Totalamount
{
    amount: string | null;
    currencyCode: string | null;
}