import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShopifyCustomerAccessToken } from 'app/modules/pages/dashboard/account/shopify-account-type';
import { ShopifyAccountService } from 'app/modules/pages/dashboard/account/shopify-account.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'user-info-form',
  templateUrl: './user-info-form.component.html',
  styleUrls: ['./user-info-form.component.scss']
})

export class UserInfoFormComponent {

  accessToken: string = "";
  infoForm: FormGroup;
  saveMessage: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShopifyCustomerAccessToken,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<UserInfoFormComponent>,
    private _shopifyAccountService: ShopifyAccountService,
    private _httpClient: HttpClient
  ) {
    this.infoForm = fb.group(
      {
        first_name: [''],
        last_name: [''],
        email: [data.customerData.email],
        mobile: [''],
        checkbox1: [true],
        checkbox2: [true],
      });
  }


  onClickYes(): void {

    //this.saveMessage ="" ;

    this._shopifyAccountService.customerAccessToken$.subscribe(sat => {
      if (sat != null) {
        this.accessToken = sat.data.customerAccessTokenCreate.customerAccessToken.accessToken;
      }
    });
    if (this.accessToken == "") {
      if (this.data.data != undefined)
        this.accessToken = this.data.data.customerAccessTokenCreate.customerAccessToken.accessToken;
    }
    var request = {
      firstName: this.infoForm.get("first_name").value,
      lastName: this.infoForm.get("last_name").value,
      phone: this.infoForm.get("mobile").value,
      accepts_marketing: this.infoForm.get("checkbox1").value,
      termCondition: this.infoForm.get("checkbox2").value
    }
    if (request.firstName == "" || request.lastName == "" || request.phone == "") {
      this.saveMessage ="Please enter required fields.";
      return;
    }
    if ( request.termCondition == false) {
      this.saveMessage ="Please agree to the Terms and Privacy Policy.";
      return;
    }
    
    let url = `${environment.url.base}${environment.url.microservice}/${'customer/UpdateCustomer'}`
    const headers = { 'content-type': 'application/json' };
    const body = JSON.stringify(request);
    const params = new HttpParams().set('accessToken', this.accessToken); //"dc03696e34ddde2b85ef6c37c9d748c2" 
    this._httpClient.post(url, body, { headers: headers, params: params }).subscribe((resultSets: any) => {
      if (resultSets != null) {
        this.dialogRef.close({ response: true, data: { firstName: request.firstName, lastName: request.lastName } });
      }
    }, error => {
      let erroMsg = error.error.split(':').length > 1 ? error.error.split(':')[1] : error.error;
      this.saveMessage = erroMsg.replace('to use this delivery method','');
    }
    );


  }

  onClickCancel(): void {
    this.dialogRef.close({ response: false });
  }
}
