import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WishlistRequest } from 'app/modules/pages/dashboard/wishlist/models/wishlistRequest';
import { environment } from 'environments/environment';
import { Observable, tap } from 'rxjs';
import { ShopifyAccountService } from '../account/shopify-account.service';

@Injectable({
    providedIn: 'root'
})

export class WishlistService {
    constructor(private _httpClient: HttpClient,
        private _shopifyAccountService: ShopifyAccountService,) { }

    updateWishlist(data: WishlistRequest): Observable<any> {
        const headers = { 'content-type': 'application/json' }
        //let url = 'https://localhost:7036/api/Customer/UpdateCustomerWishlist';
        const url = `${environment.url.base}${environment.url.microservice}/Customer/UpdateCustomerWishlist`;
        return this._httpClient.put<any>(url, data, { 'headers': headers })
            .pipe(tap(() => {
                let wishlist = JSON.parse(localStorage.getItem("WishlistItem"))
                if (data.action == 'remove') {
                    let fList = wishlist?.filter(item => item.productId != data.productId)
                    localStorage.setItem("WishlistItem", JSON.stringify(fList));
                }
                else if ((data.action == 'add')) {
                    let addedList = { productId: data.productId, title: data.title };
                    if (wishlist == null) {
                        let newList = [];
                        newList.push(addedList);
                        localStorage.setItem("WishlistItem", JSON.stringify(newList));
                    }
                    else if (!wishlist?.some((item) => item.productId == data.productId)) {
                        wishlist.push(addedList);
                        localStorage.setItem("WishlistItem", JSON.stringify(wishlist));
                    }
                }
                else {
                    localStorage.removeItem("WishlistItem")
                }
            })
        );
    }

    getWishlist(customerEmail: string): Observable<any> {
        const params = new HttpParams().set('customerEmail', customerEmail)
        //let url = 'https://localhost:7036/api/Customer/GetCustomerWishlist';
        const url = `${environment.url.base}${environment.url.microservice}/Customer/GetCustomerWishlist`;
        return this._httpClient.get<any>(url, { params })
            .pipe(tap((response) => {
                if (response?.length > 0)
                {
                    localStorage.setItem("WishlistItem", JSON.stringify(response));
                    this._shopifyAccountService.wishlistSubject.next(response);
                }
                else
                {
                    localStorage.removeItem("WishlistItem");                    
                    this._shopifyAccountService.wishlistSubject.next(null);
                }
            }));
    }
}
