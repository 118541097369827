import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcommSearchComponent } from './ecomm-search.component';
import { NgAisInstantSearch, NgAisModule } from 'angular-instantsearch';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [
    EcommSearchComponent
  ],
  imports:
    [
      BrowserModule,
      BrowserAnimationsModule,
      NgAisModule,
      CommonModule,
      MatAutocompleteModule,
      MatButtonModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      SharedModule
    ],
  exports: [
    EcommSearchComponent

  ],
  providers: [NgAisInstantSearch]
})
export class EcommSearchModule { }
