import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'app/layout/common/services/dataservice.service';

@Component({
    selector: 'reward-history',
    templateUrl: './reward-history.component.html',
    styleUrls: ['./reward-history.component.scss'],
})
export class RewardHistoryComponent implements OnInit {
    console = console;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RewardHistoryComponent>,
        private _router: Router,
        private dataservice:DataService) {}

    ngOnInit(): void {}

    ngOnDestroy(): void {}

    closeDialog() {
        this.dialogRef.close();
    }
    viewPurchaseDetail(orderNumber: number)
    {       
        this.dataservice.setOption('orderId',orderNumber.toString());
        this._router.navigate(['/purchases/detail']);
        this.dialogRef.close();
    }
}
