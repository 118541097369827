import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { ShopifyCustomerAccessToken, ShopifyShippingAddress } from './shopify-account-type';

@Injectable({
  providedIn: 'root'
})
export class ShopifyAccountService {

  private customerAccessTokenSubject: BehaviorSubject<ShopifyCustomerAccessToken | null> = new BehaviorSubject(null);
  public wishlistSubject: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private paymentWebUrlSubject: BehaviorSubject<string | null> = new BehaviorSubject("");
  private addressListSubject: BehaviorSubject<any | null> = new BehaviorSubject(null);

  constructor(private _httpClient: HttpClient) {
    this.customerAccessTokenSubject = new BehaviorSubject<ShopifyCustomerAccessToken>(JSON.parse(localStorage.getItem("CustomerAccessToken")));
    this.wishlistSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem("WishlistItem")));
    this.paymentWebUrlSubject = new BehaviorSubject<string>(localStorage.getItem("PaymentWebUrl"));
  }

  get customerAccessToken$(): Observable<ShopifyCustomerAccessToken> {
    return this.customerAccessTokenSubject.asObservable();
  }

  get wishlist$(): Observable<ShopifyCustomerAccessToken> {
    return this.wishlistSubject.asObservable();
  }
  get addressList$(): Observable<any> {
    return this.addressListSubject.asObservable();
  }

  get paymentWebUrl$(): Observable<string> {
    return this.paymentWebUrlSubject.asObservable();
  }

  public get currentCustomerAccessTokenValue(): ShopifyCustomerAccessToken {
    return this.customerAccessTokenSubject.value;
  }

  public get currentPaymentWebUrlValue(): string {
    return this.paymentWebUrlSubject.value;
  }

  setCustomerAccessToken(selectedStore: ShopifyCustomerAccessToken): Observable<ShopifyCustomerAccessToken> {
    this.updateCustomerAccessToken(selectedStore);

    return of(selectedStore);
  }

  setPaymentWebUrl(webUrl: string): Observable<string> {
    this.updatePaymentWebUrl(webUrl);

    return of(webUrl);
  }


  private updateCustomerAccessToken(accessToken: ShopifyCustomerAccessToken): void {
    if (accessToken == null) {
      localStorage.removeItem("CustomerAccessToken");
      this.customerAccessTokenSubject.next(null);
    } else {
      localStorage.setItem("CustomerAccessToken", JSON.stringify(accessToken));
      this.customerAccessTokenSubject.next(accessToken);
    }
  }

  private updatePaymentWebUrl(paymentWebUrl: string): void {
    if (paymentWebUrl == null) {
      localStorage.removeItem("PaymentWebUrl");
      this.paymentWebUrlSubject.next(null);
    } else {
      localStorage.setItem("PaymentWebUrl", paymentWebUrl);
      this.paymentWebUrlSubject.next(paymentWebUrl);
    }
  }

  getCustomerAddresses(accessToken: string): Observable<any> {
    const params = new HttpParams()
      .set('accessToken', accessToken)

    let api = `Customer/GetCustomerAddresses`;
    //let url = 'https://localhost:7036/api/Customer/GetCustomerAddresses';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`
    const headers = { 'content-type': 'application/json' }
    return this._httpClient.get(url, { params }).pipe(tap((addressList: any) => {
      this.addressListSubject.next(addressList);
    }));
  }

  getCustomerYotpoRewards(accessToken: string): Observable<any> {
    const params = new HttpParams()
      .set('customerToken', accessToken)

    let api = `Customer/CustomerYotpoRewards`;
    //let url = 'https://localhost:7036/api/Customer/CustomerYotpoRewards';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`
    const headers = { 'content-type': 'application/json' }
    return this._httpClient.get(url, { params }).pipe(tap((response) => {
      if (response?.length > 0) {
        localStorage.setItem("yotpoRewards", JSON.stringify(response));
      }
      else {
        localStorage.removeItem("yotpoRewards");
      }
    }));
  }

  addCustomerAddress(address: ShopifyShippingAddress, accessToken: string): Observable<any> {
    let api = `Customer/CreateCustomerAddress`;
    //let url = 'https://localhost:7036/api/Customer/CreateCustomerAddress';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    const headers = { 'content-type': 'application/json' }
    const params = new HttpParams()
      .set('accessToken', accessToken)

    address.name = `${address.first_name} ${address.last_name}`;
    address.company = address.company.length == 0 ? " " : address.company;
    address.address2 = address.address2.length == 0 ? " " : address.address2;
    return this._httpClient.post<any>(url, address, { headers: headers, params: params }).pipe(
      tap((response: any) => {
      })
    );
  }

  updateCustomerAddress(address: ShopifyShippingAddress, accessToken: string): Observable<any> {
    let api = `Customer/UpdateCustomerAddress`;
    //let url = 'https://localhost:7036/api/Customer/UpdateCustomerAddress';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    const headers = { 'content-type': 'application/json' }
    const params = new HttpParams()
      .set('accessToken', accessToken)

    address.name = `${address.first_name} ${address.last_name}`;
    address.company = address.company.length == 0 ? " " : address.company;
    address.address2 = address.address2.length == 0 ? " " : address.address2;
    return this._httpClient.put<any>(url, address, { 'headers': headers, params: params }).pipe(
      tap((response: any) => {
      })
    );
  }

  setCustomerDefaultAddress(address: ShopifyShippingAddress, accessToken): Observable<any> {
    let api = `Customer/SetCustomerDefaultAddress`;
    //let url = 'https://localhost:7036/api/Customer/SetCustomerDefaultAddress';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    const headers = { 'content-type': 'application/json' }
    const params = new HttpParams()
      .set('accessToken', accessToken)

    return this._httpClient.put<any>(url, address, { 'headers': headers, params: params }).pipe(
      tap((response: any) => {
      })
    );
  }

  deleteCustomerAddress(customerId: number, addressId: number, accessToken: string): Observable<any> {
    let api = `Customer/DeleteCustomerAddress`;
    const params = new HttpParams()
      .set('customerId', customerId)
      .set('addressId', addressId)
      .set('accessToken', accessToken)
    //let url = 'https://localhost:7036/api/Customer/DeleteCustomerAddress';
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    const headers = { 'content-type': 'application/json' }

    return this._httpClient.delete<any>(url, { params }).pipe(
      tap((response: any) => {
      })
    );
  }
}
