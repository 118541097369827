import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationFinderService } from 'app/layout/common/location-finder/location-finder.service';
import { StoreLocationResult } from 'app/layout/common/location-finder/location-finder.type';
import { DataService } from 'app/layout/common/services/dataservice.service';
import { ShopifyCustomerAccessToken } from 'app/modules/pages/dashboard/account/shopify-account-type';
import { ShopifyAccountService } from 'app/modules/pages/dashboard/account/shopify-account.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { BuyerIdentity, CartCreateResponse, CartInput, CartLine } from './cart-create-type';
import { CartItem, CheckoutParam, CheckoutRequest, ShippingAddress } from './cart-items-type';
import { CheckoutCreateResponse, CheckoutCustomerAssociateInput, MailingAddressInput } from './checkout-create-type';

@Injectable({
  providedIn: 'root'
})
export class CartItemsService {
  private currentCartItemsSubject: BehaviorSubject<CartItem[]>;
  public currentCartItems: Observable<CartItem[]>;
  private currentCartCreateResponseSubject: BehaviorSubject<CartCreateResponse | null> = new BehaviorSubject(null);
  public currentCartCreateResponse: Observable<CartCreateResponse>;
  private currentCheckoutCreateResponseSubject: BehaviorSubject<CheckoutCreateResponse | null> = new BehaviorSubject(null);
  public currentCheckoutCreateResponse: Observable<CheckoutCreateResponse>;
  private currentCartInputSubject: BehaviorSubject<CartInput>;
  public currentCartInput: Observable<CartInput>;
  private selectedStore: StoreLocationResult;
  private currentShippingAddressSubject: BehaviorSubject<ShippingAddress | null> = new BehaviorSubject(null);
  public currentShippingAddressResponse: Observable<ShippingAddress>;

  constructor(private _httpClient: HttpClient,
    private _locationFinderService: LocationFinderService,
    private _shopifyAccountService: ShopifyAccountService,
    private _dataService: DataService
  ) {
    this.currentCartItemsSubject = new BehaviorSubject<CartItem[]>(JSON.parse(localStorage.getItem("CartItems")));
    this.currentCartItems = this.currentCartItemsSubject.asObservable();
    this.currentCartCreateResponseSubject = new BehaviorSubject<CartCreateResponse>(JSON.parse(localStorage.getItem("CartCreateResponse")));
    this.currentCartCreateResponse = this.currentCartCreateResponseSubject.asObservable();
    this.currentCheckoutCreateResponseSubject = new BehaviorSubject<CheckoutCreateResponse>(JSON.parse(localStorage.getItem("CheckoutCreateResponse")));
    this.currentCheckoutCreateResponse = this.currentCheckoutCreateResponseSubject.asObservable();
    this.currentCartInputSubject = new BehaviorSubject<CartInput>(JSON.parse(localStorage.getItem("CartInput")));
    this.currentCartInput = this.currentCartInputSubject.asObservable();
    this.currentShippingAddressSubject = new BehaviorSubject<ShippingAddress>(JSON.parse(localStorage.getItem("ShippingAddress")));
    this.currentShippingAddressResponse = this.currentShippingAddressSubject.asObservable();
  }

  public get currentCartItemsValue(): CartItem[] {
    return this.currentCartItemsSubject.value;
  }

  get currentCartItems$(): Observable<CartItem[]> {
    return this.currentCartItemsSubject.asObservable();
  }

  public get currentCartCreateResponseValue(): CartCreateResponse {
    return this.currentCartCreateResponseSubject.value;
  }

  public get currentCheckoutCreateResponseValue(): CheckoutCreateResponse {
    return this.currentCheckoutCreateResponseSubject.value;
  }

  get currentCartCreateResponse$(): Observable<CartCreateResponse> {
    return this.currentCartCreateResponseSubject.asObservable();
  }

  get currentShippingAddress$(): Observable<ShippingAddress> {
    return this.currentShippingAddressSubject.asObservable();
  }

  get currentCheckoutCreateResponse$(): Observable<CheckoutCreateResponse> {
    return this.currentCheckoutCreateResponseSubject.asObservable();
  }

  public get currentCartInputValue(): CartInput {
    return this.currentCartInputSubject.value;
  }

  addCartItem(cartItem: CartItem): Observable<any> {
    let newCartInput: CartInput;
    let cartCreateResponse = this.currentCartCreateResponseValue;
    let api = `Purchase/Delete_CreateCart`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    const headers = { 'content-type': 'application/json' }

    if (cartCreateResponse == null) {
      //create      
      let cartLine: CartLine = { quantity: cartItem.qty, merchandiseId: cartItem.variantAdminGraphId, title: cartItem.title, image: cartItem.image, price: cartItem.price.toString() } as CartLine;
      let cartLines: CartLine[] = [];
      cartLines.push(cartLine);

      let buyerIdentity: BuyerIdentity = { email: "", countryCode: "AU", phone: "" } as BuyerIdentity;
      let customerAccessToken = this._shopifyAccountService.currentCustomerAccessTokenValue;
      if (customerAccessToken != null) {
        if (customerAccessToken.isAuthorized) {
          buyerIdentity.email = this._dataService.currentCustomerDetailsValue.email;
        }
      }

      newCartInput = { lines: cartLines, buyerIdentity: buyerIdentity } as CartInput;



      //newCartInput
      return this._httpClient.get<any>(`./assets/jsons/createcart.json`, { 'headers': headers }).pipe(
        tap((cartCreateResponse: any) => {
          //console.log('sibaram-', cartCreateResponse);
          let result = cartCreateResponse.data as CartCreateResponse;
          newCartInput.cartId = result.cartCreate.cart.id;

          result.cartCreate.cart.lines.edges.forEach((edge, index) => {
            newCartInput.lines[index].id = edge.node.id;
          });
          this.updateCurrentCartInput(newCartInput);
          this.updateCurrentCartCreateResponse(result);  // check input para
        }));
    }
    else {
      //update
      let updateCartInput = this.currentCartInputValue;
      api = `Purchase/Delete_UpdateCart`;
      url = `${environment.url.base}${environment.url.microservice}/${api}`;
      let cartLine: CartLine = { quantity: cartItem.qty, merchandiseId: cartItem.variantAdminGraphId, title: cartItem.title, image: cartItem.image, price: cartItem.price.toString() } as CartLine;

      var existingItem = updateCartInput.lines.find(e => e.merchandiseId == cartItem.variantAdminGraphId);

      if (existingItem == null) {
        updateCartInput.lines.push(cartLine);


        //put//updateCartInput
        return this._httpClient.get<any>(`./assets/jsons/createcart.json`, { 'headers': headers }).pipe(
          tap((cartCreateResponse: any) => {
            console.log('sibaram-else', cartCreateResponse);
            let result = cartCreateResponse.data as CartCreateResponse;

            var cartItemFromResponse = result.cartCreate.cart.lines.edges.find(e => e.node.merchandise.id == cartLine.merchandiseId);
            var idFromResponse = cartItemFromResponse?.node.id;

            var cartItemForUpdate = updateCartInput.lines.find(e => e.merchandiseId == cartLine.merchandiseId);
            cartItemForUpdate.id = idFromResponse;

            this.updateCurrentCartInput(updateCartInput);//check input para
            this.updateCurrentCartCreateResponse(result);//check input para
          })
        );
      }
      else {
        //siba existingItem.quantity += cartItem.qty;
        //siba cartItem.qty = existingItem.quantity;
        //siba this.updateLineQuantity(cartItem);
      }
    }
  }

  processCheckout(checkoutRequest: CheckoutRequest): Observable<any> {
    let cartCreateResponse = this.currentCartCreateResponseValue;
    let api = `Checkout/ProcessCheckout`;
    //let url = `https://localhost:7024/api/Checkout/ProcessCheckoutDraftOrder`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    const headers = { 'content-type': 'application/json' }

    let cartId = "";
    if (cartCreateResponse != null) {
      cartId = cartCreateResponse.cartCreate.cart.id;
    }

    let checkoutParam: CheckoutParam = {
      cartId: cartCreateResponse.cartCreate.cart.id,
      isGuest: (checkoutRequest.email.trim().length == 0),
      customerAccessToken: checkoutRequest.customerAccessToken,
      isClickAndCollect: checkoutRequest.isClickAndCollect,
      emailAddress: (checkoutRequest.email.trim().length == 0) ? checkoutRequest.shippingAddress.email : checkoutRequest.email,
      storeId: checkoutRequest.locationId,
      shippingAddress: checkoutRequest.shippingAddress,
      checkoutId: "",
    } as CheckoutParam;
    return this._httpClient.post<any>(url, checkoutParam, { 'headers': headers }).pipe(
      tap((checkoutCreateResponse: any) => {
        //let result = checkoutCreateResponse.data as CheckoutCreateResponse;
        //this.updateCurrentCheckoutCreateResponse(result);
      })
    );
  }

  AddCustomerToCheckout(checkoutId: string, customerAccessToken: string): Observable<any> {
    let api = `Checkout/AddCustomerToCheckout`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    const headers = { 'content-type': 'application/json' }

    let checkoutCustomerAssociateInput: CheckoutCustomerAssociateInput = { checkoutId: checkoutId, customerAccessToken: customerAccessToken } as CheckoutCustomerAssociateInput;
    return this._httpClient.post<any>(url, checkoutCustomerAssociateInput, { 'headers': headers }).pipe(
      tap((checkoutCreateResponse: any) => {
        let result = checkoutCreateResponse.data as CheckoutCreateResponse;
        //console.log("CheckoutCustomerAssociateInput", result);
        //this.updateCurrentCheckoutCreateResponse(result);
      })
    );
  }

  getShippingRate(checkoutId: string): Observable<any> {
    const params = new HttpParams()
      .set('checkoutId', checkoutId)

    let api = `Checkout/GetShippingRate`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`
    return this._httpClient.get<any>(url, { params }).pipe(
      tap((elasticSearchResult) => {
      })
    );

  }

  updateShippingAddressCheckout(checkoutId: string): Observable<any> {
    this.selectedStore = this._locationFinderService.selectedStoreValue;
    let api = `Checkout/UpdateShippingAddress`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}?checkoutId=${checkoutId}`;
    const headers = { 'content-type': 'application/json' }

    let mailingAddressInput: MailingAddressInput = {
      address1: this.selectedStore.formattedAddress,
      address2: " ",
      city: this.selectedStore.suburb,
      company: "SDC",
      country: "Australia",
      firstName: "Mark John",
      lastName: "Apostol",
      phone: this.selectedStore.phoneNumber,
      province: this.selectedStore.suburb,
      zip: "5073"
    };

    return this._httpClient.post<any>(url, mailingAddressInput, { 'headers': headers }).pipe(
      tap((checkoutCreateResponse: any) => {
        let result = checkoutCreateResponse.data;

      })
    );
  }






  addCartItem_Old(newCartItem: CartItem): Observable<CartItem> {
    var cartItems = this.currentCartItemsValue;

    if (cartItems == null) {
      cartItems = [];
      cartItems.push(newCartItem);
    }
    else {

      //search if item exists in the current cart
      var item = cartItems.find(e => e.objectID == newCartItem.objectID);
      if (item != null) {
        item.qty += newCartItem.qty;
      }
      else {
        cartItems.push(newCartItem)
      }
    }

    this.updateCurrentCartItems(cartItems);
    return of(newCartItem);
  }

  deleteCartItem(cartItem: CartItem) {
    var cartItems = this.currentCartItemsValue;

    // let api = `Purchase/RemoveCart`;
    // let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    // const headers = { 'content-type': 'application/json' }

    let updateCartInput = this.currentCartInputValue;
    let updateCartCreateResponse = this.currentCartCreateResponseValue;

    //create
    let cartLine: CartLine = { quantity: 1, merchandiseId: cartItem.variantAdminGraphId, title: cartItem.title, image: cartItem.image, price: cartItem.price.toString() } as CartLine;
    let cartLines: CartLine[] = [];
    let cartInputLine = updateCartInput.lines.find(e => e.merchandiseId == cartItem.variantAdminGraphId);
    cartLine.merchandiseId = cartInputLine.id;
    cartLines.push(cartLine);

    var cartInput: CartInput = Object.assign({}, updateCartInput);
    cartInput.lines = cartLines;
    if (cartInput.buyerIdentity.phone == undefined || cartInput.buyerIdentity.phone == null) {
      cartInput.buyerIdentity.phone = "0";
    }

    //Todo: add validation to check if cartitem is available in response or not
    var index = updateCartInput.lines.findIndex(e => e.merchandiseId == cartItem.variantAdminGraphId);
    updateCartInput.lines.splice(index, 1);
    this.updateCurrentCartInput(updateCartInput);

    this.updateCurrentCartCreateResponse(updateCartCreateResponse);

    if (cartItems != null) {
      //Todo: add validation to check if cartitem is available in response or not
      var index = cartItems.findIndex(e => e.objectID == cartItem.objectID);
      cartItems.splice(index, 1);
      //console.log(cartItems);
      this.updateCurrentCartItems(cartItems);
    }

  }

  deleteCartItems(cartItemsToRemove: CartItem[]): Observable<CartItem> {

    var cartItems = this.currentCartItemsValue;

    let api = `Purchase/RemoveCart`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`;
    const headers = { 'content-type': 'application/json' }

    let updateCartInput = this.currentCartInputValue;
    let updateCartCreateResponse = this.currentCartCreateResponseValue;

    //create
    //let cartLine: CartLine = { quantity: 1, merchandiseId: cartItem.variantAdminGraphId, title: cartItem.title, image: cartItem.image, price: cartItem.price.toString() } as CartLine;
    let cartLinesToDelete: CartLine[] = [];

    cartItemsToRemove.forEach(cartLineToRemove => {
      let cartLine: CartLine = { quantity: 1, merchandiseId: cartLineToRemove.variantAdminGraphId, title: cartLineToRemove.title, image: cartLineToRemove.image, price: cartLineToRemove.price.toString() } as CartLine;
      let cartInputLine = updateCartInput.lines.find(e => e.merchandiseId == cartLineToRemove.variantAdminGraphId);
      cartLine.merchandiseId = cartInputLine.id;

      cartLinesToDelete.push(cartLine);
    });

    var cartInput: CartInput = Object.assign({}, updateCartInput);
    cartInput.lines = cartLinesToDelete;
    if (cartInput.buyerIdentity.phone == undefined || cartInput.buyerIdentity.phone == null) {
      cartInput.buyerIdentity.phone = "0";
    }

    return this._httpClient.put<any>(url, cartInput, { 'headers': headers }).pipe(
      tap((cartCreateResponse: any) => {
        let result = cartCreateResponse.data as CartCreateResponse;

        result.cartCreate.cart.lines.edges.forEach((edge, index) => {
          updateCartInput.lines[index].id = edge.node.id;
        });
        //Todo: add validation to check if cartitem is available in response or not
        cartItemsToRemove.forEach(cartLineToRemove => {
          var index = updateCartInput.lines.findIndex(e => e.merchandiseId == cartLineToRemove.variantAdminGraphId);
          updateCartInput.lines.splice(index, 1);
          this.updateCurrentCartInput(updateCartInput);
        });

        //Todo: add validation to check if cartitem is available in response or not
        //New code
        updateCartCreateResponse.cartCreate = result.cartCreate;
        //New code


        //Old actual code
        // index = updateCartCreateResponse.cartCreate.cart.lines.edges.findIndex(e => e.node.merchandise.id == cartItem.variantAdminGraphId);
        // updateCartCreateResponse.cartCreate.cart.cost = result.cartCreate.cart.cost;
        // updateCartCreateResponse.cartCreate.cart.lines.edges.splice(index, 1);
        //Old actual code

        this.updateCurrentCartCreateResponse(updateCartCreateResponse);

        if (cartItems != null) {
          //Todo: add validation to check if cartitem is available in response or not
          cartItemsToRemove.forEach(cartLineToRemove => {
            var index = cartItems.findIndex(e => e.objectID == cartLineToRemove.objectID);
            cartItems.splice(index, 1);
            this.updateCurrentCartItems(cartItems);
          });
        }
      })
    );
  }

  deleteUnavailableCartItem(cartItem: CartItem[]) {
    if (cartItem != null) {
      this.updateCurrentCartItems(cartItem);
    }
  }

  ajdustQtyCartItem(cartItem: CartItem): Observable<CartItem> {
    var cartItems = this.currentCartItemsValue;

    if (cartItems != null) {
      var item = cartItems.find(e => e.objectID == cartItem.objectID);
      if (item != null) {
        item.qty = cartItem.qty;
        this.updateCurrentCartItems(cartItems);
        return of(item);
      }
    }
    return of(null);
  }

  public updateCurrentCartItems(cartItems: CartItem[]): void {
    if (cartItems == null) {
      localStorage.removeItem("CartItems");
      this.currentCartItemsSubject.next(null);
    } else {
      localStorage.setItem("CartItems", JSON.stringify(cartItems));
      this.currentCartItemsSubject.next(cartItems);
    }
  }

  public updateCurrentCartCreateResponse(cartCreateResponse: CartCreateResponse): void {
    if (cartCreateResponse == null)
      localStorage.removeItem("CartCreateResponse");
    else
      localStorage.setItem("CartCreateResponse", JSON.stringify(cartCreateResponse));
    //console.log(cartCreateResponse);
    this.currentCartCreateResponseSubject.next(cartCreateResponse);
  }

  public updateShippingAddress(shippingAddress: ShippingAddress): void {
    this.updateCurrentShippingAddress(shippingAddress);
  }

  private updateCurrentShippingAddress(shippingAddress: ShippingAddress): void {
    if (shippingAddress == null)
      localStorage.removeItem("ShippingAddress");
    else
      localStorage.setItem("ShippingAddress", JSON.stringify(shippingAddress));

    this.currentShippingAddressSubject.next(shippingAddress);
  }

  public updateCurrentCartInput(cartInput: CartInput): void {
    if (cartInput == null)
      localStorage.removeItem("CartInput");
    else
      localStorage.setItem("CartInput", JSON.stringify(cartInput));

    this.currentCartInputSubject.next(cartInput);
  }

  public updateCurrentCheckoutCreateResponse(checkoutCreateResponse: CheckoutCreateResponse): void {
    if (checkoutCreateResponse == null)
      localStorage.removeItem("CheckoutCreateResponse");
    else
      localStorage.setItem("CheckoutCreateResponse", JSON.stringify(checkoutCreateResponse));

    this.currentCheckoutCreateResponseSubject.next(checkoutCreateResponse);
  }

  // getCartItems(cartId: string): Observable<any> {
  //   const params = new HttpParams()
  //     .set('cartId', cartId)

  //   let api = `Purchase/GetCart`;
  //   //let url = 'https://localhost:7024/api/Purchase/GetCart';
  //   let url = `${environment.url.base}${environment.url.microservice}/${api}`
  //   const headers = { 'content-type': 'application/json' }
  //   return this._httpClient.get(`./assets/jsons/getcart.json`);
  // }

  getCustomerCart(customerAccessToken: string): Observable<any> {
    const params = new HttpParams()
      .set('customerAccessToken', customerAccessToken)

    let api = `Purchase/GetCustomerCart`;
    let url = `${environment.url.base}${environment.url.microservice}/${api}`
    const headers = { 'content-type': 'application/json' }
    return this._httpClient.get(url, { params });
  }

  public clearCartLocalStorage() {

    var cartInput = {
      cartId: "",
      lines: [],
      buyerIdentity: null
    } as CartInput

    this.updateCurrentCartItems([]);

    this.currentCartInputSubject.next(cartInput);
    this.updateCurrentCartCreateResponse(null);

    localStorage.removeItem("CartInput");
    localStorage.removeItem("CartItems");
    localStorage.removeItem("CartCreateResponse");
  }

  sendMailToUser(inputmessage: string,uname:string): Observable<any> {
    var data = {
      service_id: 'service_b3n41mo',
      template_id: 'template_nr31vvi',
      user_id: 'Lho45iA1XblFwAMUv',
      template_params: {
        'to_name': uname,
        'from_name': 'pramila',
        'message': inputmessage
      }
    };
    let api = `https://api.emailjs.com/api/v1.0/email/send`;
    //let inputData = JSON.stringify(data)
    const headers = { 'content-type': 'application/json' }

    return this._httpClient.post<any>(api, data, { 'headers': headers });

  }

  tempOTP():any
  {
    let list=[{otp:'1010'},{otp:'2002'},{otp:'3300'},{otp:'4040'},{otp:'5050'},{otp:'6600'},
    {otp:'7007'},{otp:'8800'},{otp:'9009'},{otp:'0010'}];
    return list;
  }
  public ManageOTP(otp: string): void {
    if (otp == null)
      localStorage.removeItem("otp");
    else
      localStorage.setItem("otp", otp);    
  }
  public GetManagedOTP(key: string): string {
   return localStorage.getItem(key);   
  }
}
